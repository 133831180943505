<template>

	<CardContainer :title="title" :subtitle="subtitle" :showsAction="false">
	<div class="list pt-1">
		<div
			class="grid gap-4 gap-y-4 grid-cols-1 md:grid-cols-2 md:gap-10 md:gap-y-6 "
		>
			<template v-for="item in this.list?.slice(0,12)" :key="item.id">
                <NewsItem :imageURL="item.thumbnail?.thumbnail" :title="item.title" :desc="item.abstract" :url="item.detail_link" :date="item.display_date"></NewsItem>
			</template>
		</div>
	</div>
</CardContainer>
</template>

<script>

import CardContainer from '@/components/cards/CardContainer.vue'
import NewsItem from '@/components/cards/subcards/NewsListItem.vue'

export default {
    props: {
        title: { type: String, default: "资讯"},
        subtitle: { type: String},
        list: Object
    },
  name: 'NewsCard',
  components: {
    CardContainer,
    NewsItem
  },
  data () {
    return {
        // title: "资讯",
        }
    }
}
</script>

<style scoped>

</style>