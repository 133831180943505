<template>
	<CardContainer :title="title" :subtitle="subtitle" :showsAction="false">
		<ImageTextTBContent
			:imagesrc="imageURL"
			:title="text"
			:rawContent="rawContent"
		></ImageTextTBContent>
	</CardContainer>
</template>

<script>
import CardContainer from "@/components/cards/CardContainer.vue";
import ImageTextTBContent from "@/components/cards/subcards/ImageTextTBContent.vue";

export default {
	props: {
		imageURL: String,
		text: String,
		rawContent: String,
		title: { type: String, default: "简介"},
		subtitle: { type: String},
	},
	name: "IntroCard",
	components: {
		CardContainer,
		ImageTextTBContent,
	},
	data() {
		return {
			// title: "简介",
		};
	},
};
</script>

<style scoped></style>
