function setupThemeStyles(config) {
    /**
     * 
     * 
     * 
     *     --theme-text-color: #0192FF;

    --theme-gradient-color-dark: #5636F2;
    --theme-gradient-color-light: #0192FF;

    --theme-header-background: linear-gradient(132deg, var(--theme-gradient-color-dark) 0%, var(--theme-gradient-color-light) 100%);
    --theme-header-text-color: white;
     */
    // prebuilt themes

    if (config?.theme_name) {
      let toFilter = [];
      document.documentElement.classList.forEach(function(x) {
        if (x.startsWith("theme-")) {
          toFilter.push(x)
        }
      });
      toFilter.forEach(function(x) {
        document.documentElement.classList.remove(x)
      })

      document.documentElement.classList.add("theme-" + config.theme_name)
    }
    setupDefaultUsingConfig(config)
}

function setupDefaultUsingConfig(config) {
  let headerBackgroundCSS = config?.header_background_css
  if (headerBackgroundCSS && headerBackgroundCSS?.length > 0) {
    document.documentElement.style.setProperty('--theme-header-background', headerBackgroundCSS);
  }
  let headerTextColor = config?.header_text_color
  if (headerTextColor && headerTextColor?.length > 0) {
    document.documentElement.style.setProperty('--theme-header-text-color', headerTextColor);
  }
  let themeTextColor = config?.theme_text_color
  if (themeTextColor && themeTextColor?.length > 0) {
    document.documentElement.style.setProperty('--theme-text-color', themeTextColor);
  }
}

export {
  setupThemeStyles
}