<template>

<div class="content-card p-4 md:p-6 lg:p-12 lg:py-8">
  
  <div class="card-title-bar large hidden md:block md:pb-6 md:mb-0"  v-if="showsTitleBarLg">
      <div class="card-title" v-if="this.title && this.title?.trim().length > 0">{{title}}</div>
      <div class="card-subtitle" v-if="this.subtitle && this.subtitle?.trim().length > 0">{{subtitle}}</div>
    </div>

      <div class="card-title-bar md:hidden"  v-if="showsTitleBar">
      <div class="card-title-container">
        <div class="card-title">{{title}}</div>
        <button v-if="showsAction" class="card-tap-action" @click="showDetail">
          <div>{{action}}</div>
          <div class="indicator-view"></div>
        </button>
      </div>
      <div class="card-subtitle">{{subtitle}}</div>
    </div>

    <div class="content-item">
    <slot></slot>
    </div>

    <div class="action-container-lg hidden md:block" v-if="showsAction">
        <button class="bottom-action-button-lg" @click="showDetail">
                    <div>{{action}}</div>
                  <div class="indicator-view"></div>
        </button>
    </div>
</div>

</template>

<script>

export default {
  props : {
    // showsTitleBar: {
    //   type: Boolean,
    //   default: true,
    // },
    title : {
      type: String
    },
    subtitle: {
      type: String,
    },
    action : {
      type: String,
      default : "更多"
    },
    showsAction: {
      type: Boolean,
      default: false
    },
    actionLink : {
      type: String,
    }
  },
  name: 'CardContainer',
  components: {
  },
  computed: {
    showsTitleBar() {
      return (this.title && this.title?.trim().length > 0) || 
      this.showsAction || 
      (this.subtitle && this.subtitle?.trim().length > 0);
    },
    showsTitleBarLg() {
      return (this.title && this.title?.trim().length > 0) || 
      (this.subtitle && this.subtitle?.trim().length > 0);
    }
  },
  data () {
    return {

      }
    },
    methods: {
      showDetail : function () {
        // console.log(this.actionLink);
        if (this.actionLink) {
          window.open(this.actionLink, "_blank");
        }
      },
    }
}
</script>

<style scoped>

.content-card {
   width: auto; 
   background-color: white;

   margin: var(--element-spacing-normal) 0;
   border-radius: 8px;
   transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.card-tap-action {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.card-tap-action > * {
margin-left: 4px;
}

/* .content-card {
    padding: var(--element-padding-normal);
} */

.card-title-bar {
    /* margin-top: 8px; */
    margin-bottom: 10px;
}

.card-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 20px;
}

.card-title {
    /* font-size: 14px; */
      font-size: 15px;

    font-weight: 450;
    color: #303133;
}

.card-subtitle {
    font-size: 13px;
    font-weight: normal;
    color: #919192;
    margin-top: 4px;
}

.card-tap-action {
    font-size: 12px;
    color: var(--theme-text-color);
    filter: brightness(0.9);
    height: 100%;
}

.card-tap-action > * {
  line-height: 100%;
}

.indicator-arrow {
  height: 12px;
  object-fit: contain;
  object-position: center;
}

.indicator-view {
    height: 12px;
    width: 8px;
    mask-size: contain;
    -webkit-mask-size: contain;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-image: url("~@/assets/images/card-arrow.png");
    -webkit-mask-image: url("~@/assets/images/card-arrow.png");
    background-color: var(--theme-text-color);
}

.large .card-title {
font-size: 22px;
font-weight: bold;
text-align: center;
padding: 0 8%;
line-height:100%;
}
.large .card-subtitle {
font-size: 14px;
font-weight: normal;
text-align: center;
padding: 0 8%;
line-height: 150%;
margin-top:6px;
}
.large.card-title-bar {
margin-bottom: 0;
}

.bottom-action-button-lg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 18px;
  font-size: 14px;
	color: var(--theme-text-color);
  font-weight: bold;
  opacity: 0.9;
}

.bottom-action-button-lg .indicator-view {
  margin-left: 4px;
}


</style>