<template>
	<CardContainer :title="title" :subtitle="subtitle" :showsAction="false" @click="this.showAll=true">	
		<div class="history-container flex items-stretc">
			<div class="bar-container flex-none">
				<div class="timeline-bar"></div>
			</div>
			<div class="item-container flex-1 pb-1">
				<ul class="flex flex-col space-y-4">
					<li
						class="history-item"
						v-for="item in this.displayItems"
						:key="item.id"
					>
						<div class="timeline-bar-dot"></div>
						<a
							class="grid md:grid-cols-8 xl:grid-cols-9 items-start overflow-hidden"
						>
							<time
								class="date-label md:col-start-1 md:col-span-2 row-start-1 md:row-end-3 flex items-center font-medium mb-1 md:mb-0 "
							>
								{{ item.dateString }}
							</time>
							<p class="item-content col-start-1 md:col-start-3 md:col-span-6">
								{{ item.content }}
							</p>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</CardContainer>
</template>

<script>
import CardContainer from "@/components/cards/CardContainer.vue";

export default {
	props: {
		list: Object,
		title: { type: String, default: "历程"},
		subtitle: { type: String},
	},
	name: "HistoryCard",
	components: {
		CardContainer,
	},
	computed: {
		displayItems: function() {
			let list = this.list;
			if (!list) {
				list = [];
			}

			list.sort(function (a, b) {
				return b.dateTimestamp - a.dateTimestamp;
			});
			// console.log(list);

			if (this.showAll) {
				return list;
			} else {
				return list?.slice(0, 5);
			}
		},
	},
	data() {
		return {
			// title: "历程",
			showAll: false,
		};
	},
};
</script>

<style scoped>
.bar-container {
	height: auto;
	width: 24px;
}

.item-container {
	height: 100%;
}

.timeline-bar {
	width: 4px;
	margin: 1% auto;
	margin-right: 12px;
	height: 98%;
	border-radius: 2px;
	background: linear-gradient(
		180deg,
		var(--theme-text-color) 0%,
		var(--theme-text-color) 60%,
		rgba(255, 255, 255, 0) 100%
	);
}

.history-item {
	position: relative;
}
.timeline-bar-dot {
	position: absolute;
	top: 7px;
	left: -18px;
	width: 8px;
	height: 8px;
	border-radius: 4px;
	border: solid 1.5px white;
	background-color: var(--theme-text-color);
}

.date-label {
	opacity: 0.8;
	font-size: 15px;
	font-weight: 500;
}
.item-content {
	font-size: 14px;
}
</style>
