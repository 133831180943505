<template>
	<div
		class="pb-1 overflow-hidden transition-shadow duration-300 bg-white rounded"
	>
		<a :href="url" target="_blank" aria-label="Article">
			<div
				class="relative group image aspect-w-16 aspect-h-9 overflow-hidden rounded-sm"
			>
				<img :src="imageURL" class="object-cover rounded" alt="" />
				<div
					class="absolute inset-0 flex items-center justify-center w-full h-full transition-colors duration-300 bg-gray-900 bg-opacity-0 group-hover:bg-opacity-40"
				>
					<div
						class="flex items-center justify-center w-12 h-12 transition duration-300 group-hover:scale-110"
					>
						<div class="play w-full h-full"></div>
					</div>
				</div>
			</div>
		</a>
		<div class="pt-2 pb-1">
			<h3 class="title line-clamp-3">
				{{ title }}
			</h3>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		desc: String,
		title: String,
		imageURL: String,
		url: String,
		date: String,
	},
	name: "VideoGridItem",
	components: {},
	data() {
		return {};
	},
	computed: {},
};
</script>

<style scoped>
.title {
	font-size: 14px;
	font-weight: 500;
	opacity: 0.9;
}

.play {
	background-size: contain;
	background-image: url("~@/assets/images/icon-play.png");
}

img {
	background-color:lightgray;
    opacity: 0.0;
    border: none;
}

img[src^="http"] {
	display: block;
	background: none;
	opacity: 1;
}
img[lazy=loading],img[lazy=loaded],img[lazy=error] {
	background-color: rgba(250,250,250,20);
}

@media (min-width: 768px) {

	.title {
		font-size: 15px;
	}
}

</style>
