function createLogoImage(imgSources, asMask = false) {
	return Promise.all(
		imgSources.map(function(url) {
			// load all images
			// console.log(url);
			return new Promise(function(resolve) {
				var img = new Image();
				img.crossOrigin = "anonymous";
				img.onload = function() {
					resolve(img);
				};
				img.src = url;
			});
		})
	).then(function(images) {
		// console.log(images);

		// fit to height.
		const containerHeight = 120;
		// const itemSeparatorSpacing = 40;

		let drawInfos = [];
		let imageFullWidth = 0;
		// calculate full size
		images.forEach((image) => {
			let info = {};
			info.image = image;

			if (image.width > 1 && image.height > 1) {
				info.imageWidth = image.width;
				info.imageHeight = image.height;

				let scale = containerHeight / info.imageHeight;

				info.drawWidth = info.imageWidth * scale;
				info.drawHeight = containerHeight;

				imageFullWidth += info.drawWidth;

				drawInfos.push(info);
			}
		});

		// setup canvas
		let canvas = document.createElement("canvas");
		canvas.height = containerHeight;
		canvas.width = imageFullWidth;
		if (drawInfos.length > 1) {
			// 加上间距
			// canvas.width += (drawInfos.length - 1) *(itemSeparatorSpacing * 2 + 1);
		}

		// draw images to the canvas
		var ctx = canvas.getContext("2d");

		let advance = 0;
		// drawInfos.forEach((drawInfo, index, array) => {
		drawInfos.forEach((drawInfo) => {
			// draw image

			// console.log(drawInfo);

			ctx.drawImage(
				drawInfo.image,
				0,
				0,
				drawInfo.imageWidth,
				drawInfo.imageHeight,
				advance,
				0,
				drawInfo.drawWidth,
				drawInfo.drawHeight
			);

			// advanced by draw width.
			advance += drawInfo.drawWidth;

			// // draw separator if needed
			// if (index + 1 < array.length) {

			//     advance += itemSeparatorSpacing;
			//     // draw separator
			//     ctx.fillStyle = "white";
			//     ctx.fillRect(20, advance, 3, 80);

			//     // advanced by draw separator width.
			//     advance += itemSeparatorSpacing + 1;
			// }
		});

		if (asMask) {

			// ctx.globalCompositeOperation = "source-in";
			// ctx.fillStyle = "black";
			// ctx.fillRect(0, 0, canvas.width, canvas.height);

			// remove all white pixels

			var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
			var pixel = imageData.data;

			var r = 0,
				g = 1,
				b = 2,
				a = 3;
			const threshold = 245;
			for (var p = 0; p < pixel.length; p += 4) {
				if (pixel[p + r] > threshold && pixel[p + g] > threshold && pixel[p + b] > threshold) {
					// if near white then change alpha to 0
					pixel[p + a] = 0;
				}
			}

			ctx.putImageData(imageData, 0, 0);
		}
		let dataURL = canvas.toDataURL("image/png");
		return dataURL;
	});
}

export { createLogoImage };
