<template>
	<div>
		<vue-final-modal
			v-model="show"
			name="mapAction"
			classes="flex justify-center items-center"
		>
			<template v-slot="{ params }">
				<div class="flex flex-col gap-y-4 items-center self-stretch">
					<div
						class="modal-container flex flex-col items-center border bg-white"
					>
						<div class="title-container mt-4 mb-3">
							<div class="title">选择一种导航方式{{ params.title }}</div>
						</div>
						<div class="action-container flex flex-col">
							<template v-for="action in this.mapActions" :key="action.type">
								<button
									class="action-button button"
									@click="handleMap(action, params)"
								>
									{{ action.name }}
								</button>
							</template>
							<button class="cancel-button button" @click="show = false">
								关闭
							</button>
						</div>
					</div>
				</div>
			</template>
		</vue-final-modal>
	</div>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
import {
	OpenMapWithAction,
	GetSupportedMapActions,
} from "@/utils/map_utils.js";

// https://vue-final-modal.org/zh-Hant/api
export default {
	components: {
		VueFinalModal,
	},
	data: () => ({
		show: false,
		mapActions: GetSupportedMapActions(),
	}),
	watch: {
		show: function(val) {
			if (!val) {
				// make some cleanups?
			}
		},
	},
	methods: {
		handleMap(action, params) {
			OpenMapWithAction(
				action,
				params.longitude,
				params.latitude,
				params.address
			);
            // close
            this.show = false;
		},
	},
};
</script>
<style scoped>
.modal-container {
	border-radius: 12px;
	overflow: hidden;
}

.button {
	height: 40px;
	width: 280px;
	/* border-radius: 8px;
	border: solid #cccccc;
	border-width: 1px; */
	font-size: 17px;
	color: var(--theme-text-color);
	border-top: 1px solid #ddd;
}
.cancel-button {
	color: #333;
}

.title {
	font-size: 15px;
	font-weight: bold;
	color: #333;
}
</style>
