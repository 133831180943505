<template>
	<!-- 1. loading -->
	<div class="loading" v-if="!initialDataReady && !initialDataError && !showsGuide">
		{{ loadingText }}
	</div>

	<!-- 2. failed:registered -->
	<div class="guide" v-if="showsGuide">
		<ConfigGuidePage :domain="guideDomain"></ConfigGuidePage>
	</div>

	<!-- 3. failed:unregistered, jump to www.co.ltd. -->

	<!-- 4. failed:other errors -->
	<div class="error" v-if="initialDataError">{{ initialDataErrorMessage }}</div>

	<!-- 5. success:show contents -->
	<div class="main-page-container" v-if="initialDataReady && !initialDataError">
		<MainPageContainer
			:displayData="displayData"
			:displayConfig="displayConfig"
		></MainPageContainer>
		<QRCodeDialog></QRCodeDialog>
		<CardCodeDialog></CardCodeDialog>
		<MapActionDialog></MapActionDialog>
	</div>
</template>

<script>
// 测试多步加载

import MainPageContainer from "@/components/container/MainPageContainer.vue";
import QRCodeDialog from "@/components/modals/QRCodeDialog.vue";
import CardCodeDialog from "@/components/modals/CardCodeDialog.vue";
import MapActionDialog from "@/components/modals/MapActionDialog.vue";
import ConfigGuidePage from "@/components/intro/ConfigGuide.vue";

import { decode } from "html-entities";

import axios from "axios";

// mock
// import response from "@/mock/response/digitalgate_info.json";
// import { mockResponseData } from "@/mock/response/mockresponse.js";
import {
	prepareDisplayInfoData,
	prepareDisplayContentsData,
	capitalizedCoLtdDomainString,
} from "@/utils/display_data_setup.js";
import { setupThemeStyles } from "@/utils/theme_setup.js";
import { getBuiltinDisplayConfigs } from "@/utils/builtin_display_configs.js";
import { getDisplayDomain } from "@/utils/utilities.js";

import textVersion from "textversionjs";

export default {
	name: "Main",
	data() {
		return {
			displayData: {
				contents: {},
			},
			displayConfig: {},
			configData: {},
			initialDataReady: false,
			initialDataError: false,
			initialDataErrorMessage: "",
			requestErrors: [],
			showsIntro: true,
			guideDomain: "",
			showsGuide: false,
		};
	},
	computed: {
		loadingText() {
			// let host = window.location.host;
		
			let host = getDisplayDomain();
			if (host?.length > 0) {
				return " 正在进入数字门户…\n" + capitalizedCoLtdDomainString(host);
			} else {
				return " 正在进入数字门户…";
			}
		},
	},
	components: {
		MainPageContainer,
		QRCodeDialog,
		CardCodeDialog,
		MapActionDialog,
		ConfigGuidePage,
	},
	methods: {
		setupData(infoData, configData) {
			setupThemeStyles(configData);

			this.displayData.info = prepareDisplayInfoData(infoData, configData);
			Object.assign(
				this.displayData.contents,
				prepareDisplayContentsData(infoData)
			);

			this.configData = configData;
			// console.log(configData);

			// this.initialDataReady = true;

			if (process.env.NODE_ENV == "development") {
				// console.log(process.env.NODE_ENV);
			} else {
				// 只有当生产环境才发送
				const websiteId = infoData.info.website_id;
				// console.log(websiteId);
				const scriptLabel = document.createElement("script");
				scriptLabel.innerText = `_ga.url = 'https://wei.ltd.com/analyst';_ga.params['wi'] = '3';_ga.params['ui'] ='0';_ga.init(${websiteId});`;
				document.querySelector("body").appendChild(scriptLabel);
			}

			// get display config
			// built-in has higher priority
			this.displayConfig = getBuiltinDisplayConfigs(infoData.info.website_id);
			
			if (this.displayConfig && this.displayConfig.display) {
				// ignore
			}
			else {
				this.displayConfig = configData.displayconfig;
			}
			
			// if (this.displayConfig && this.displayConfig.display) {
			// 	// ignore
			// 	// use this.
			// } else {
			// 	// try to get default configs.
			// 	this.displayConfig = getBuiltinDisplayConfigs(infoData.info.website_id);
			// }

			// console.log(this.displayConfig);

			this.displayConfig = this.displayConfig ? this.displayConfig : {};


			// other
			this.displayConfig.otherConfigs = {};
			this.displayConfig.otherConfigs.domainShopType = this.configData.domain_shop_type; 
			// console.log(this.displayConfig.otherConfigs.domainShopType);

			this.updateDocumentMeta();
		},
		updateContentsData(contentsData) {
			let displayContentsData = prepareDisplayContentsData(contentsData);

			// console.log(displayContentsData);
			// console.log(displayContentsData);

			Object.assign(this.displayData.contents, displayContentsData);
			// console.log(this.displayData.contents);
		},
		updateDocumentMeta() {
			let displayName = this.displayData?.info?.preferredDGDisplayName;
			if (!displayName) {
				displayName = "";
			}

			let docTitle = this.displayData?.info?.preferredDGDisplayName;
			if (docTitle && docTitle.length > 0) {
				document.title = docTitle;
			} else {
				document.title = "数字门户";
			}

			let desc = "";
			try {
				desc = textVersion(this.displayData?.info?.intro?.rawContent);
				desc = desc ? decode(desc) : "";
				desc = desc.replace(/\n+/g, " ");
			} catch (error) {
				// error
			}
			if (!desc || desc.length == 0) {
				desc = "";
			}
			{
				let existed = false;
				let meta = document.getElementsByTagName("meta");
				for (let i = 0; i < meta.length; i++) {
					if (meta[i].name.toLowerCase() == "description") {
						existed = true;
						meta[i].content = desc;
					}
				}
				if (!existed) {
					let meta = document.createElement("meta");
					meta.setAttribute("name", "description");
					meta.content = desc;
					document.getElementsByTagName("head")[0].appendChild(meta);
				}
			}
			let metaProperties = {
				"og:title": docTitle,
				"og:image": this.displayData?.info?.preferredShareImageURL,
				keywords:
					displayName + ",数字门户,.Co.Ltd,公司名即域名,营销SaaS,营销枢纽,22.cn,LTD.com",
				"apple-mobile-web-app-title": document.title,
			};
			let linkProperties = {
				"apple-touch-icon": this.displayData?.info?.avatarLogoURL,
			};

			let dgDomainURL = this.displayData?.info?.headerInfo?.dgDomain;
			if (dgDomainURL && dgDomainURL.length > 0) {
				metaProperties["og:url"] = "https://" + dgDomainURL;
			}
			// console.log(metaProperties);

			for (let property in metaProperties) {
				let content = metaProperties[property];
				// console.log(property + " : " + content );

				if (content) {
					let existed = false;
					let meta = document.getElementsByTagName("meta");
					for (let i = 0; i < meta.length; i++) {
						if (meta[i].getAttribute("property") == property) {
							existed = true;
							meta[i].content = content;
						}
					}
					if (!existed) {
						let meta = document.createElement("meta");
						meta.setAttribute("property", property);
						meta.content = content;
						document.getElementsByTagName("head")[0].appendChild(meta);
					}
				}
			}

			for (let rel in linkProperties) {
				let href = linkProperties[rel];
				// console.log(property + " : " + content );

				if (href) {
					// just add
					let link = document.createElement("link");
					link.setAttribute("rel", rel);
					link.href = href;
					document.getElementsByTagName("head")[0].appendChild(link);
				}
			}

			// favicon
			{
				let avatarURL = this.displayData?.info?.avatarLogoURL;
				if (avatarURL) {
					let existed = false;
					let meta = document.getElementsByTagName("link");
					for (let i = 0; i < meta.length; i++) {
						if (meta[i].getAttribute("rel") == "icon") {
							existed = true;
							meta[i].href = avatarURL;
						}
					}
					if (!existed) {
						let meta = document.createElement("link");
						meta.setAttribute("rel", "icon");
						meta.href = avatarURL;
						document.getElementsByTagName("head")[0].appendChild(meta);
					}
				}
			}
			// <link rel="shortcut icon" href="https://uploadfile.xcx.gw66.vip/uploadfile/image/0/16/222/2020-12/16085379926518.jpg">
		},
		jumpToHomepage() {
			window.location.replace("https://www.co.ltd/");
		},
	},
	mounted() {
		// console.log("mounted");
		// console.log(mockResponseData())

		// this.prepareData();

		let info = "/api/digitalgate/info?data=info";
		let config = "/api/digitalgate/config";

		// test
		// info = "https://random.co.ltd/api/digitalgate/info?data=info";
		// config = "https://random.co.ltd/api/digitalgate/config";

		let contentsReqs = [
			"/api/digitalgate/info?data=members,notice,activity,case",
			"/api/digitalgate/info?data=product,article",
			"/api/digitalgate/info?data=partner,company_client,social_accounts,video",
			// 下面类型暂不支持显示
			// "/api/digitalgate/info?data=knowledge,recruit,faq,media_reports",
		];

		// let host = window.location.host;
		// let coltddomain = punycodeDecoder.toUnicode(host);

		let hostDomain = window.location.host;
		let coltddomain = getDisplayDomain();

		// coltddomain = "testtest.co.ltd"; // 测试
		const checkDomainRequest =
			"https://www.22.cn/coltd/api/checkdomain.ashx?domain=" + hostDomain;
		// console.log(checkDomainRequest);
		
		this.guideDomain = coltddomain;

		let that = this;

		axios
			.all([axios.get(info), axios.get(config)])
			.then(
				axios.spread((...responses) => {
					// console.log(responses);
					let infoResponse = responses[0];
					let configResponse = responses[1];

					let infoResponseData = infoResponse.data?.data;
					let configResponseData = configResponse.data?.data;

					that.setupData(infoResponseData, configResponseData);

					const websiteId = infoResponseData.info?.website_id;
					// if (websiteId > 0) {
						// success
						// update global saved website id.
						this.$store.commit('updateWebsiteId', websiteId);
						that.initialDataReady = true;
					// }
					// else {
					// 	// something goes wrong.
					// }
				})
			)
			.catch((errors) => {
				// console.log(errors);
				// console.log(errors.responses);
				
				// 2022-05-14 10:00:54
				// 个别域名会没有返回responses，暂时跟404一样处理
				if (!errors.responses || errors.response?.status == 404) {
					const shouldCheckDomain = true;
					
					if (shouldCheckDomain) {
						// detect if domain is registered.
						axios
							.get(checkDomainRequest)
							.then((res) => {
								// console.log(res);
								// success, shows guide.
								const status = res?.data?.data?.status;
								// console.log("status: " + status);
								if (status) {
									// registered
									that.showsGuide = true;
								}
								else {
									that.jumpToHomepage();
								}
							})
							.catch((error) => {
								// window.location.replace("https://www.co.ltd/");
								that.jumpToHomepage();
								// console.log(error);
								error;
							});
					} else {
						// not found
						// redirect to 'https://www.22.cn/CoLtd/'
						that.jumpToHomepage();
						// window.location.replace("https://www.co.ltd/");
					}
				} else {
					// shows error page.
					that.initialDataError = true;
					that.initialDataErrorMessage = "发生错误，请重试";
					// console.log(errors);
				}

				that.requestErrors = errors;
			});

		contentsReqs.forEach((url) => {
			axios.get(url).then((response) => {
				let contentsResponseData = response.data?.data;
				that.updateContentsData(contentsResponseData);
			}).catch((error)=>{error;});
		});
	},
};
</script>
<style scoped>
.loading,
.error {
	color: #888;
	font-size: 15px;
	position: fixed; /* or absolute */
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	white-space: pre-wrap;
	line-height: 24px;
}
</style>
