<template>
	<div class="list">
		<div
			class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 grid-container"
		>
		<template v-for="item in this.list" :key="item.domain">
          <DomainShopItem :domain="item.domain" :lefttime="item.lefttime" :desc="item.desc" :url="item.url" :price="item.price" :priceType="item.type" :domainType="item.name"></DomainShopItem>
		</template>
		</div>
	</div>
</template>

<script>

import DomainShopItem from "./DomainShopItem.vue";
// 			class="grid gap-3 row-gap-3 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:row-gap-4 md:gap-4"

export default {
	props: {
		list: Object,
	},
	name: "DomainShopList",
	components: {
		DomainShopItem,
	},
	data() {
		return {
		};
	},
};
</script>

<style scoped>
/* .list {
    background: #eee;
} */
.grid-container {
    /* gap:1px; */
    gap: 0;
}
</style>
