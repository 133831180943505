<template>

    <template v-for="section in this.list" :key="section.title">

        <CardContainer :title="section.title" :subtitle="section.subtitle" :showsAction="section.link?.length > 0" :actionLink="section.link" :action="section.linkname">
            <DomainListContent :list="section.list"></DomainListContent>
        </CardContainer>

    </template>
</template>

<script>

// a container contains multiple cards
import DomainListContent from "./DomainShopList.vue";
import CardContainer from "@/components/cards/CardContainer.vue";

export default {
	props: {
		list: Object,
	},
	name: "DomainShopListCardsContainer",
	components: {
		CardContainer,
        DomainListContent,
	},
	data() {
		return {
		};
	},
};

</script>

<style scoped>

</style>
