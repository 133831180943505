<template>

	<CardContainer :title="title" :subtitle="subtitle" :showsAction="false">
	<div class="list">
		<div
			class="grid gap-3 row-gap-3 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:row-gap-4 md:gap-4"
		>
			<template v-for="item in this.list?.slice(0,12)" :key="item.id">
          <ProductItem :imageURL="item.thumbnail?.thumbnail" :title="item.title" :desc="item.abstract" :url="item.link" :price="item.price" :preferredAspectRatio=
        "preferredAspectRatio"></ProductItem>
			</template>
		</div>
	</div>
</CardContainer>
</template>

<script>

import CardContainer from '@/components/cards/CardContainer.vue'
import ProductItem from '@/components/cards/subcards/ProductGridItem.vue'

export default {
    props: {
        title: { type: String, default: "产品服务"},
        list: Object,
        subtitle: { type: String},
    },
  name: 'ProductsCard',
  components: {
    CardContainer,
    ProductItem
  },
  mounted() {
      this.updatePreferredAspectRatio(this.list);
  },
  watch: {
    list: {
      handler(newValue) {
        // update aspect ratio with new value.
        this.updatePreferredAspectRatio(newValue);
      },
      deep: false
    }
  },
  data () {
    return {
        preferredAspectRatio: 1.7777,
    }
  },
  methods: {
    updatePreferredAspectRatio: function (list) {
      // 平均值
      let aspectRatioSum = 0;
      let aspectRaioCount = 0;
      list.forEach(function(x) {
        let w = x.thumbnail?.width;
        let h = x.thumbnail?.height;
        if (h > 0) {
          let aspectRatio = w/h;
          // 排除一些明显特别扁和特别长条的比例
          if (aspectRatio < 3 && aspectRatio > 0.5) {
            aspectRatioSum += aspectRatio;
            aspectRaioCount ++;
          }
        }
      });
      if (aspectRaioCount > 0) {
        let aspectRatio = aspectRatioSum / aspectRaioCount;
        this.preferredAspectRatio = aspectRatio;
      }
      else {
        this.preferredAspectRatio = 1.77777;
      }
      // console.log(this.preferredAspectRatio);
    }
  }
}
</script>

<style scoped>

</style>