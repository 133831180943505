<template>
	<CardContainer :title="title" :subtitle="subtitle" :showsAction="false">
		<div class="list" v-if="this.list?.length > 0">
			<template v-for="item in this.list.slice(0, 1)" :key="item.id">
				<a class="item" :href="item.detail_link" target="_blank">
					<div class="date">{{ item.display_date }}</div>
					<div class="title">{{ item.title }}</div>
				</a>
			</template>
		</div>
	</CardContainer>
</template>

<script>

import CardContainer from '@/components/cards/CardContainer.vue'

export default {
  props: {
    list: Object,
	title: { type: String, default: "公告"},
	subtitle: { type: String},
  },
  name: 'AnnouncementCard',
  components: {
    CardContainer
  },
  data () {
    return {
		// title: "公告",
      }
    }
}
</script>

<style scoped>
/* 公告 */
.item > .date {
	font-size: 12px;
	font-weight: 400;
	font-family: sans-serif;
	color: #888;
}
.item .title {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	margin-top: 4px;
	font-size: 14px;
}

.item > .title {
	font-size: 14px;
	font-weight: 500;
	color: var(--theme-text-color);
	filter: saturate(1.5);
}

@media (min-width: 768px) {

	.item .title {
		font-size: 15px;
	}

	.item > .date {
	font-size: 14px;
	}
}

</style>
