<template>
<div class="static-map-container">
    <img class="static-map-image" :src="tencentMapImageURL" />
</div>
</template>
<script>
// https://www.npmjs.com/package/vue-clipboard2
export default {
	props: {
        latitude: {
            type: Number,
            default: 0.00,
        },
        longitude: {
            type: Number,
            default: 0.00,
        },
    },
	data() {
		return {};
	},
	computed: {
		tencentMapImageURL: function() {
            // let marker_image_url = require("@/assets/images/icon-map-marker.png");
            
			// https://apis.map.qq.com/ws/staticmap/v2/?center=39.8802147,116.415794&scale=2&zoom=10&size=500*400&maptype=roadmap&markers=size:large|color:0xFFCCFF|label:k|39.8802147,116.415794&key=RBMBZ-VOACI-W7ZGO-5KPTA-6TDBV-SXFIY
			let locationParams = "" + this.latitude + ","+ this.longitude;
            return "https://apis.map.qq.com/ws/staticmap/v2/?scale=2&zoom=15&size=400*300&maptype=roadmap&markers=size:large|color:0xFFCCFF|label:k|"+ locationParams + "&key=RBMBZ-VOACI-W7ZGO-5KPTA-6TDBV-SXFIY"+"&center=" + locationParams;
		},
	},
	methods: {},
};
</script>
<style scoped>
.static-map-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
</style>
