<template>
	<CardContainer :title="title" :showsAction="false">
		<form
			@submit="submit"
			action="/web_message.json"
			method="post"
			class="form-container"
			data-form-name="数字门户留言"
		>
			<div class="flex flex-col w-full mt-0">
				<p class="leading-relaxed mb-2 text-gray-600">
					{{this.subtitle}}
				</p>
				<div class="relative mb-4">
					<label for="name" class="leading-7 text-sm text-gray-600"
						>姓名<span class="required-indicator">*</span></label
					>
					<input
						type="text"
						id="name"
						name="姓名"
						required
						v-model="form.name"
						class="w-full bg-white rounded-lg border border-gray-300   text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
					/>
				</div>
				<div class="relative mb-4">
					<label for="phone" class="leading-7 text-sm text-gray-600"
						>联系方式<span class="required-indicator">*</span></label
					>
					<input
						type="phone"
						id="phone"
						name="联系方式"
						required
						v-model="form.phone"
						class="w-full bg-white rounded-lg  border border-gray-300  text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
					/>
				</div>
				<div class="relative mb-4">
					<label for="message" class="leading-7 text-sm text-gray-600"
						>留言内容</label
					>
					<textarea
						id="message"
						name="留言内容"
						v-model="form.message"
						class="w-full bg-white rounded-lg  border border-gray-300  h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
					></textarea>
				</div>
				<input
					type="submit"
					:value="submitButtonText"
					class="submit-buttom js-submit"
					ref="submitButton"
					:disabled="submitDisabled"
				/>
			</div>
		</form>
	</CardContainer>
</template>

<script>
import CardContainer from "@/components/cards/CardContainer.vue";
import axios from "axios";

export default {
	props: {
		title: { type: String, default: "留言反馈"},
		subtitle: { type: String, default: "请留下您的联系方式与反馈："},
	},
	name: "MessageCard",
	components: {
		CardContainer,
	},
	data() {
		return {
			// title: "留言反馈",
			submitDisabled: false,
			submitButtonText: "提交留言",
			form: {
				name: "",
				message: "",
				phone: "",
			},
		};
	},
	methods: {
		submit: function(e) {
			let that = this;
			let formData = new FormData();
			formData.append("name", "数字门户留言");
			formData.append("姓名", this.form.name);
			formData.append("联系方式", this.form.phone);
			formData.append("留言内容", this.form.message);
			let instance = this;
			this.submitDisabled = true;
			this.submitButtonText = "提交中";

			axios({
				method: "post",
				url: "/api/digitalgate/message",
				data: formData,
				headers: { "Content-Type": "multipart/form-data" },
			})
				.then(function(response) {
					let msg = response.data?.msg;
					if (!msg || msg.length == 0) {
						msg = "已提交";
					}
					// cleanup
					let cleanup = false;
					if (response.data.code == 0) {
						cleanup = true;
					}
					alert(msg);
					if (cleanup) {
						instance.form = {
							name: "",
							message: "",
							phone: "",
						};
					}
					instance.submitDisabled = false;
					instance.submitButtonText = "提交留言";

// trigger refresh.
					that.$store.commit('needsUpdateStatisticData');
				})
				.catch(function(error) {
					let msg = error.message;
					if (!msg || msg?.length == 0) {
						msg = "提交失败";
					}
					alert(msg + ", 请重试");

					instance.submitDisabled = false;
					instance.submitButtonText = "提交留言";
				});
			e.preventDefault();
		},
	},
	computed: {},
};
</script>

<style scoped>
.submit-buttom {
	background-color: var(--theme-text-color);
	color: white;
	height: 40px;
	border-radius: 20px;
	width: 100%;
	font-weight: 600;
	font-size: 15px;
}
.submit-buttom:disabled {
	opacity: 0.3;
}

.required-indicator {
	color: red;
	margin-left: 4px;
	font-weight: bold;
}
.form-container {
	max-width: 400px;
	margin: 0 auto;
	margin-bottom: 8px;
}
</style>
