<template>

	<CardContainer :title="title" :subtitle="subtitle" :showsAction="false">
	<div class="list pt-1">
		<div
			class="grid gap-y-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 sm:gap-6 sm:gap-y-4 "
		>
			<template v-for="item in this.list?.slice(0,6)" :key="item.id">
                <VideoItem :imageURL="item.previewImageURL" :title="item.title" :desc="item.abstract" :url="item.detail_link" :date="item.display_date"></VideoItem>
			</template>
		</div>
	</div>
</CardContainer>
</template>

<script>

import CardContainer from '@/components/cards/CardContainer.vue'
import VideoItem from '@/components/cards/subcards/VideoGridItem.vue'

export default {
    props: {
        title: { type: String, default: "视频"},
		subtitle: { type: String},
        list: Object
    },
    name: 'VideosCard',
  components: {
    CardContainer,
    VideoItem
  },
  data () {
        return {
            // title: "视频",
        }
    }
}
</script>

<style scoped>

</style>