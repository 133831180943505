<template>
	<div class="md:hidden list">
		<div class="item">
			<template  v-if="!showAll">
				<div class="image" v-if="imagesrc?.length > 0">
					<img :src="imagesrc" />
				</div>
			</template>
			<template v-else>
				<div class="image show-all" v-if="imagesrc?.length > 0">
					<img :src="imagesrc" />
				</div>
			</template>
			<div v-if="displayText?.length > 0">
				<div v-if="!showAll" class="title show-short" @click="showAllContent">
					<div class="raw-html"  v-html="displayHTML">
					</div>
					<!-- {{ displayText }} -->
				</div>
				<div v-if="showAll" class="title show-all">
					<!-- {{ displayText }} -->
					<div class="raw-html"  v-html="displayHTML">
					</div>
				</div>
			</div>

			<!-- <div v-if="title?.length > 0" class="title line-clamp-3">{{ title }}</div> -->
			<!-- <div class="raw-content-container" v-if="rawContent?.length > 0">
				<div v-html="rawContent" class="raw-content"></div>
				<div class="more">更多</div>
			</div> -->
		</div>
	</div>

<!-- Large size -->

	<div class="hidden md:block large">
	<!-- only image, no image, both. -->
	<!-- both -->
	<template v-if="displayText && displayText?.length > 0 && imagesrc && imagesrc?.length > 0">

		<div class="pt-2 grid gap-2 grid-cols-1 md:grid-cols-2 md:gap-x-8 lg:gap-x-12 items-start">
				<div class="image show-all" v-if="imagesrc?.length > 0">
					<img :src="imagesrc" />
				</div>
				<!-- <div class="title">
					{{ displayText }}
				</div> -->
				<div class="title">
					<div class="raw-html"  v-html="displayHTML">
					</div>
				</div>
		</div>
	</template>
	<template v-else-if="displayText && displayText?.length > 0">
		<div class="pt-2">
				<!-- <div class="title">
					{{ displayText }}
				</div> -->
				<div class="title">
					<div class="raw-html" v-html="displayHTML">
					</div>
				</div>
		</div>
	</template>
	<template v-else>
		<div class="">
			<div class="image show-all" v-if="imagesrc?.length > 0">
				<img :src="imagesrc" />
			</div>
		</div>
	</template>

	</div>
</template>

<script>
// https://www.npmjs.com/package/textversionjs
import textVersion from "textversionjs";
import { decode } from "html-entities";
import sanitizeHtml from 'sanitize-html';

export default {
	props: {
		imagesrc: String,
		url: String,
		title: String,
		rawContent: String,
	},
	computed: {
		displayHTML() {
			let html = "";
			if (this.title && this.title.trim().length > 0) {
				html = "<div>" + this.title.trim() + "</div>";
			}
			else {				
				try {
					html = this.sanitizedHTML(this.rawContent);
				} catch (error) {
					// nothing.
				}
			}
			return html;
		},
		displayText() {
			// console.log(this.rawContent);
			let text = this.title;
			if (!text || text.length == 0) {
				try {
					const styleConfig = {
						linkProcess: function(href, linkText){
							return linkText;
						}
					};
					let converted = textVersion(this.rawContent, styleConfig);
					// console.log(converted)

					text = decode(converted);
					// console.log(text)
				} catch (error) {
					// console.log(error)
				}
			}

			return text;
		},
	},
	methods: {
		showAllContent() {
			this.showAll = true;
		},
		sanitizedHTML(rawContent) {
			// console.log(rawContent);
			const clean = sanitizeHtml(rawContent, {
				allowedTags: [ 'b', 'i', 'em', 'strong', 'a', 'p', 'div', 'br'],
				allowedAttributes: {
					'a': [ 'href' ]
				},
			});
			// console.log(clean);
			return clean;
		},
	},
	name: "ImageTextTBContent",
	components: {},
	data() {
		return {
			showAll: false,
		};
	},
};
</script>

<style scoped>
.raw-content {
	font-size: 14px;
	font-weight: normal;
	color: #606266;
	line-height: 150%;
}
.raw-content-container {
	position: relative;
}
.more {
	position: absolute;
	left: 0;
	width: 100%;
	bottom: -16px;
	height: 32px;
	color: var(--theme-text-color);
	font-size: 13px;
	text-align: center;
	background: linear-gradient(90deg, white 0%, rgba(255, 255, 255, 0) 100%);
	z-index: 999;
	margin-top: -24px;
	margin-bottom: 0px;
}

/* 活动 */
.item {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 4px;
}

.item >>> .image {
	display: flex;
	flex-direction: column;

	max-height: 720px;
	
	overflow: hidden;
	padding: 0;
}

.item >>> .image.show-all {
	max-height: 720px;
}

.item > .image > img {
	
	object-fit: cover;
	object-position: center;
	width: 100%;
	height: 100%;
	margin: 0;
	border-radius: 3px;
}

.item >>> .title {
	font-weight: 400;
	color: #606266;
	white-space: pre-wrap;
}

.large >>> .title {
	font-weight: normal;
	font-size: 15px;
	line-height: 175%;
	white-space: pre-wrap;
}

.large >>> .image {
	max-height: 480px;
	overflow: hidden;
}

.large >>> .image img {
	object-fit: contain;
	/* match container's height */
	max-height: 480px;
	width: 100%;
}

.limit-three-lines {
	/* 不支持的就直接展示吧 */
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3; /* number of lines to show */
	-webkit-box-orient: vertical;
}

.item >>> .title {
	text-overflow: ellipsis;
	overflow: hidden;
	margin-top: 4px;
	font-size: 14px;
	line-height: 150%;
}

img[lazy=loading],img[lazy=loaded],img[lazy=error] {
	background-color: rgba(250,250,250,20);
}

.raw-html >>> a {
	text-underline-offset: 2px;
	text-decoration: underline;
	/* text-decoration-thickness: 2px; */
	/* text-decoration-color: var(--theme-text-color); */
}

.raw-html, .raw-html >>> * {
	white-space: normal !important;
	margin: 0;
	padding: 0;
	line-height: 175%;
}

.raw-html >>> b, .raw-html strong {
	font-weight: bold;
	color: black;
}

.title.show-short >>> .raw-html br {
display: none;
}

.title.show-short {
	max-height: 120px;
	position: relative;
	overflow: hidden;
}

.title.show-short:after {
  content  : "";
  position : absolute;
  z-index  : 1;
  left     : 0;
  pointer-events   : none;
  background-image : linear-gradient(to bottom, 
                    rgba(255,255,255, 0), 
                    rgba(255,255,255, 1) 70%);
  width    : 100%;
    top   : 102px;
	/* if .show-short is shorter than 92px, it won't be shown */
  height: 24px; 
}

</style>
