<template>
	<div class="content-container">
		<template
			v-for="displayItem in this.preparedDisplayConfig"
			:key="displayItem.key"
		>
			<template v-if="displayItem.type === 'banners'">
				<Banners
					v-if="info?.banners?.length > 0"
					:list="info?.banners"
					:subtitle="displayItem?.config?.subtitle"
				></Banners>
			</template>

			<template v-else-if="displayItem.type === 'notice'">
				<Announcement
					v-if="contents?.noticeList?.length > 0"
					:list="contents?.noticeList"
					:title="displayItem?.config?.title"
					:subtitle="displayItem?.config?.subtitle"
				></Announcement>
			</template>
			<template v-else-if="displayItem.type === 'activity'">
				<Activity
					v-if="contents?.activityList?.length > 0"
					:list="contents?.activityList"
					:title="displayItem?.config?.title"
					:subtitle="displayItem?.config?.subtitle"
				></Activity>
			</template>

			<template v-else-if="displayItem.type === 'websites'">
				<Websites
					v-if="
						info?.officialLinks?.website?.length > 0 ||
							info?.officialLinks?.miniprogram?.length > 0 ||
							info?.officialLinks?.socialAccount?.length > 0
					"
					:websites="info?.officialLinks?.website"
					:miniprograms="info?.officialLinks?.miniprogram"
					:socialAccounts="info?.officialLinks?.socialAccount"
					:title="displayItem?.config?.title"
					:subtitle="displayItem?.config?.subtitle"
				></Websites>
			</template>

			<template v-else-if="displayItem.type === 'shops'">
				<Stores
					v-if="
						info?.officialLinks?.officialShop?.length > 0 ||
							info?.officialLinks?.otherShops?.length > 0
					"
					:officialShops="info?.officialLinks?.officialShop"
					:otherShops="info?.officialLinks?.otherShops"
					:title="displayItem?.config?.title"
					:subtitle="displayItem?.config?.subtitle"
				></Stores>
			</template>

			<template v-else-if="displayItem.type === 'intro'">
				<Intro
					v-if="info?.intro"
					:rawContent="info?.intro?.rawContent"
					:imageURL="info?.intro?.imageURL"
					:title="displayItem?.config?.title"
					:subtitle="displayItem?.config?.subtitle"
				></Intro>
			</template>

			<template v-else-if="displayItem.type === 'story'">
				<Story
					v-if="info?.story"
					:rawContent="info?.story?.rawContent"
					:imageURL="info?.story?.imageURL"
					:title="displayItem?.config?.title"
					:subtitle="displayItem?.config?.subtitle"
				></Story>
			</template>

			<template v-else-if="displayItem.type === 'members'">
				<Members
					v-if="contents?.members?.length > 0"
					:list="contents?.members"
					:title="displayItem?.config?.title"
					:subtitle="displayItem?.config?.subtitle"
				></Members>
			</template>

			<template v-else-if="displayItem.type === 'atlases'">
				<Album
					v-if="contents?.albumList?.length > 0"
					:list="contents?.albumList"
					:title="displayItem?.config?.title"
					:subtitle="displayItem?.config?.subtitle"
				></Album>
			</template>

			<template v-else-if="displayItem.type === 'honer'">
				<Honors
					v-if="contents?.honorList?.length > 0"
					:list="contents?.honorList"
					:title="displayItem?.config?.title"
					:subtitle="displayItem?.config?.subtitle"
				></Honors>
			</template>

			<template v-else-if="displayItem.type === 'course'">
				<History
					v-if="contents?.historyList?.length > 0"
					:list="contents?.historyList"
					:title="displayItem?.config?.title"
					:subtitle="displayItem?.config?.subtitle"
				></History>
			</template>

			<template v-else-if="displayItem.type === 'case'">
				<Cases
					v-if="contents?.caseList?.length > 0"
					:list="contents?.caseList"
					:title="displayItem?.config?.title"
					:subtitle="displayItem?.config?.subtitle"
				></Cases>
			</template>

			<template v-else-if="displayItem.type === 'product'">
				<Products
					v-if="contents?.productList?.length > 0"
					:list="contents?.productList"
					:title="displayItem?.config?.title"
					:subtitle="displayItem?.config?.subtitle"
				></Products>
			<!-- 暂时放在产品后面 -->
			<!-- 当不为hidden时候，显示 -->
				<template v-if="displayConfig.otherConfigs.domainShopType != 'hidden'">
					<DomainShop></DomainShop>
				</template>
			</template>

			<template v-else-if="displayItem.type === 'partner'">
				<Partners
					v-if="contents?.partners?.length > 0"
					:list="contents?.partners"
					:title="displayItem?.config?.title"
					:subtitle="displayItem?.config?.subtitle"
					:info="info"
				></Partners>
			</template>

			<template v-else-if="displayItem.type === 'company_client'">
				<Clients
					v-if="contents?.company_client?.length > 0"
					:list="contents?.company_client"
					:title="displayItem?.config?.title"
					:subtitle="displayItem?.config?.subtitle"
				></Clients>
			</template>

			<template v-else-if="displayItem.type === 'article'">
				<News
					v-if="contents?.articleList?.length > 0"
					:list="contents?.articleList"
					:title="displayItem?.config?.title"
					:subtitle="displayItem?.config?.subtitle"
				></News>
			</template>
			<template v-else-if="displayItem.type === 'video'">
				<Videos
					v-if="contents?.videoList?.length > 0"
					:list="contents?.videoList"
					:title="displayItem?.config?.title"
					:subtitle="displayItem?.config?.subtitle"
				></Videos>
			</template>
			<template v-else-if="displayItem.type === 'social_accounts'">
				<Follow
					v-if="contents?.socialAccountList?.length > 0"
					:list="contents?.socialAccountList"
					:title="displayItem?.config?.title"
					:subtitle="displayItem?.config?.subtitle"
				></Follow>
			</template>
			<template v-else-if="displayItem.type === 'contact'">
				<Contact
					v-if="info?.contactInfo"
					:address="info?.contactInfo?.address"
					:location="info?.contactInfo?.geoLocation"
					:phone="info?.contactInfo?.phone"
					:email="info?.contactInfo?.email"
					:contact="info?.contactInfo?.name"
					:title="displayItem?.config?.title"
					:subtitle="displayItem?.config?.subtitle"
				></Contact>
			</template>
			<template v-else-if="displayItem.type === 'message'">
				<Message
					:title="displayItem?.config?.title"
					:subtitle="displayItem?.config?.subtitle"
				></Message>
			</template>
		</template>
	</div>
</template>

<script>
import Banners from "@/components/cards/Banners.vue";
import Activity from "@/components/cards/Activity.vue";
import Announcement from "@/components/cards/Announcement.vue";
import Websites from "@/components/cards/Websites.vue";
import Stores from "@/components/cards/Stores.vue";
import Intro from "@/components/cards/Intro.vue";
import Members from "@/components/cards/Members.vue";
import Story from "@/components/cards/Story.vue";
import Album from "@/components/cards/Album.vue";
import Honors from "@/components/cards/Honors.vue";
import History from "@/components/cards/History.vue";
import Cases from "@/components/cards/Cases.vue";
import Products from "@/components/cards/Products.vue";
import Partners from "@/components/cards/Partners.vue";
import News from "@/components/cards/News.vue";
import Videos from "@/components/cards/Videos.vue";
import Follow from "@/components/cards/SocialMedia.vue";
import Contact from "@/components/cards/Contact.vue";
import Message from "@/components/cards/Message.vue";
import Clients from "@/components/cards/Clients.vue";
import DomainShop from "@/components/container/DomainShop.vue";

import { prepareDisplayConfig } from "@/utils/display_config.js";

export default {
	props: {
		info: Object,
		contents: Object,
		displayConfig: {
			type: Object,
			default: function() {
				return {};
			},
		},
	},
	computed: {
		preparedDisplayConfig() {
			// console.log(this.displayConfig);
			// console.log(this.displayConfig.otherConfigs.domainShopType);
			
			return prepareDisplayConfig(this.displayConfig);
		},
	},
	name: "CardList",
	components: {
		Banners,
		Activity,
		Announcement,
		Websites,
		Stores,
		Intro,
		Members,
		Story,
		Album,
		Honors,
		History,
		Cases,
		Products,
		Partners,
		Clients,
		News,
		Videos,
		Follow,
		Contact,
		Message,
		DomainShop,
	},
	data() {
		return {};
	},
};
</script>

<style scoped>
/* .content-container {
  padding-bottom: 120px;
} */
</style>
