<template>
	<div class="item-container">
		<div class="icon">
			<img v-if="iconURL && iconURL.length > 0" :src="iconURL" />
		</div>
		<div class="info-container url-bar">
			<div class="url-bar-background"></div>

				<a :href="url" target="_blank" class="url-container">
					<div class="url-content">{{ this.displayName }}</div>
				</a>
				<div class="action-container">
					<CopyButton :content="this.url">
						<span class="website-action-copy">复制</span>
					</CopyButton>
				</div>
		</div>
	</div>
</template>

<script>
import CopyButton from "@/components/cards/functional/CopyButton.vue";

export default {
	props: {
		type: String,
		name: String,
		url: String,
	},
	name: "StoreListItem",
	components: {
		CopyButton,
	},
	data() {
		return {
			icons: {
				jd: require("@/assets/images/SocialMedias/shop_jd.png"),
				taobao: require("@/assets/images/SocialMedias/shop_taobao.png"),
				pdd: require("@/assets/images/SocialMedias/shop_pdd.png"),
				tmall: require("@/assets/images/SocialMedias/shop_tmall.png"),
				default: require("@/assets/images/SocialMedias/shop_default.png"),
			},
		};
	},
	computed: {
		iconURL: function() {
			let type = "default";
			try {
				let urlURL = new URL(this.url);
				if (urlURL) {
					let host = urlURL.host;
					if (host.endsWith(".jd.com")) {
						type = "jd";
					} else if (host.endsWith(".taobao.com")) {
						type = "taobao";
					} else if (host.endsWith(".pdd.com")) {
						type = "pdd";
					} else if (host.endsWith(".tmall.com")) {
						type = "tmall";
					} else {
						type = "default";
					}
				}
			} catch (error) {
				//
			}
			return this.icons[type];
		},
		displayName: function() {
			return this.name;
		},
	},
};
</script>

<style scoped>
.item-container {
	display: flex;
	height: 44px;
	align-items: center;
}

.icon {
	height: 32px;
	width: 32px;

	flex-grow: 0;
	flex-shrink: 0;

	/* placeholder */
	background-color: #f0f0f0;
	border-radius: 5px;
}
.icon > img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.info-container {
	height: 36px;
	margin-left: 12px;
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	background-color: #f9f9f9;
	border-radius: 4px;
	align-items: center;
	justify-content: space-between;
}

.info-container .qr {
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: 12px;
	height: 14px;
	width: 14px;
	mask-image: url("~@/assets/images/icon-qr.png");
	-webkit-mask-image: url("~@/assets/images/icon-qr.png");
	mask-size: contain;
    -webkit-mask-size: contain;
	background: #888;
}

.url-bar {
	height: 32px;
	width: auto;
	border-radius: 22px;
	border: solid var(--theme-text-color);
	border-width: 1px;
	color: var(--theme-text-color);

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0 16px;

	position: relative;
	overflow: hidden;
}

.url-bar-background {
	pointer-events: none;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	background-color: var(--theme-text-color);
	opacity: 0.05;
}

.url-ssl {
	width: 11px;
	height: 16px;
	background-color: var(--theme-text-color);

	margin-right: 12px;
	mask-image: url("~@/assets/images/icon-ssl.png");
	-webkit-mask-image: url("~@/assets/images/icon-ssl.png");
	mask-size: contain;
	-webkit-mask-size: contain;
	mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
}

.url-bar .url-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.url-content {
	font-size: 14px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	font-weight: 500;
}

.url-bar .action-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 14px;
}

.action-copy {
	display: block;
}
</style>
