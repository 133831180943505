<template>
<div class="statistic-container">
    <StatisticItem type="exposures" :number="exposures"></StatisticItem>
    <StatisticItem type="views" :number="views"></StatisticItem>
    <StatisticItem type="messages" :number="messages"></StatisticItem>
    <div class="spacer"></div>
    <StatisticItem type="likes" :number="likes"></StatisticItem>
</div>
</template>

<script>

import StatisticItem from "./StatisticItem.vue";
import axios from "axios";

export default {
    components : {
        StatisticItem
    },
    name: 'Statistic',
    props: {
    },
	data() {
		return {
            likes: 0,
            messages: 0,
            views: 0,
            exposures: 0,
		};
	},
    computed: {
        statisticDataSession() {
            return this.$store.state.statisticDataSession;
        }
    },
    watch: {
        statisticDataSession (newVal, oldVal) {
            if (newVal > oldVal) {
                // console.log("updated session " + newVal);
                this.updateData();
            }
        }
    },
    methods: {
    updateData() {
            const api = "/api/digitalgate/data";
            axios
            .get(api)
            .then((res) => {
                // "interact_count": 42580,
                // "gui count": 2731,
                // "card count": 2602,
                // "mini count": 267,
                // "media_count": 4,
                // "other count": 28812,
                // "register count": 1347.
                // "messages": 35896,
                // "visit_nums": 1164038,
                // "order nums": 777,
                // "exposure_nums": 78171057,
                // "member_nums": 1347,
                // "like_count" : 111
                const data = res.data?.data;
                // console.log(data);
                // console.log(data.messages);
                // console.log(parseInt(data.messages));

                if (data) {
                    if (data?.visit_nums) {
                        this.views = parseInt(data.pv_total_num);
                    }
                    else {
                        this.views = 0;
                    }
                    if (data?.messages) {
                        this.messages = parseInt(data.messages);
                    }
                    else {
                        this.messages = 0;
                    }
                    if (data?.like_count) {
                        this.likes = parseInt(data.like_count);
                    }
                    else {
                        this.likes = 0;
                    }
                    if (data?.exposure_nums) {
                        this.exposures = parseInt(data.exposure_nums);
                    }
                    else {
                        this.exposures = 0;
                    }
                }
            })
            .catch((error) => {
                error;
            });
      },
    },
    mounted() {
        this.updateData();
    },
}

</script>

<style scoped>

.statistic-container {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: center;
    gap: 16px;
    height: 36px;
    /* max-width: 320px; */
    margin: 0 auto;
}
.spacer {
    display: none;
}
/* .container {
    display: none;
} */

@media screen and (max-width: 554px) {
    .statistic-container {
        max-width: 95%;
        /* width: auto; */
        /* margin: 0 12px; */
        /* justify-content: space-between; */
        gap: 8px;
    }
    /* .spacer {
        display: block;
        flex: 1 1;
    } */
}

</style>
