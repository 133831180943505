<template>
	<!-- 卡片里面的二级卡片容器 -->
	<div class="sub-container">
		<div v-if="title" class="sub-title">{{ title }}</div>
        <div v-if="title" class="separate-spacing"></div>

		<div class="sub-container-content">
			<slot>
				<!-- 放置实际的内容 -->
			</slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "SubContentContainer",
	components: {},
	props: {
		title: String,
	},
	data() {
		return {};
	},
};
</script>

<style scoped>
.sub-container {
	padding: 16px;
}
.separate-spacing {
    height: 16px;
}

.sub-title {
	height: 15px;
	font-weight: 500;
	font-size: 14px;
}

.sub-container {
	width: auto;
	background-color: white;
	border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.09);
}
</style>
