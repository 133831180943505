export function prepareDisplayConfig(serverConfig) {

        let config = [];

        // 准备display
        if (Array.isArray(serverConfig.display)) {
            serverConfig.display.forEach(function(item) {
                config.push(item);
            });
        }
        else {
            // 使用默认数据
            config = [].concat(defaultDisplayItems)
        }

        // 过滤需要隐藏的数据
        // filter any 不合法的数据
        config = config.filter(function(item) {
            if (parseInt(item.hidden) === 1) {
                return false;
            }
            return true;
        }).filter(x => x.type && x.type.length > 0);
        
        // 准备config
        let itemConfigs = {};
        // itemConfigs = defaultDisplayConfigs;
        
        if (serverConfig.config) {

            Object.keys(serverConfig.config).forEach(function(key) {
                // 让key对应的value是一个Object，才能执行后面的Object.assign()
                if (itemConfigs[key]) {
                    // ignore
                }
                else {
                    itemConfigs[key] = {};
                }
                // merge
                let serverItem = Object.assign({}, serverConfig.config[key]);
                
                // console.log(serverItem);
                    // delete serverItem.title;
                    // console.log(serverItem.title.length);
                    // if (serverItem.title.length === 0) {
                    //     console.log("try");
                    // }
                    // if (serverItem.title != null) {
                    //     console.log("try 2");
                    // }

                if (serverItem.title != null && serverItem.title.length === 0) {
                    delete serverItem.title;
                }
                // if (serverItem.subtitle && serverItem.subtitle?.length == 0) {
                //     delete serverItem.subtitle;
                // }
                // console.log(key);
                // console.log(serverItem);

                Object.assign(itemConfigs[key], serverItem);


            });    
        }

        // apply to display config
        config.forEach(function(item) {
            let itemConfig = itemConfigs[item.name];
            if (itemConfig) {
                item.config = itemConfig;
            }
        });

        // // insert domain shop after **FIRST** product section
        // let inserted = false;
        // if (serverConfig?.otherConfigs?.domainShopType != 'hidden') {
        //     config.forEach(function(item) {
        //         if (item.type == "product") {
        //             if (!inserted) {
                        
        //             }
        //             inserted = true;
        //         }
        //     });
        // }

        // make key
        config.forEach(function(item) {
            item.key = item.type + '+' + (item.name ? item.name : "_");
        });




    return config;
}

// const defaultDisplayConfigs = {
// "intro" : {
//     // "title" : "介绍",
// },
// "message" : {
//     // "title" : "给我留言",
// },
// // "members2" : {
// //     "title" : "核心成员",
// // }
// }


const defaultDisplayItems = [
    {
        "name" : "notice",
        "type" : "notice",
    },
    {
        "name" : "activity",
        "type" : "activity",
    },
    {
        "name" : "websites",
        "type" : "websites",
    },
    {
        "name" : "shops",
        "type" : "shops",
    },
    {
        "name" : "intro",
        "type" : "intro",
    },
    {
        "name" : "story",
        "type" : "story",
    },
    // {
    //     "name" : "members",
    //     "type" : "members",
    // },
    {
        "name" : "atlases",
        "type" : "atlases",
    },
    {
        "name" : "honer",
        "type" : "honer",
    },
    {
        "name" : "course",
        "type" : "course",
    },
    {
        "name" : "case",
        "type" : "case",
    },
    {
        "name" : "product",
        "type" : "product",
    },
    {
        "name" : "partner",
        "type" : "partner",
    },
    {
        "name" : "company_client",
        "type" : "company_client",
    },
    {
        "name" : "article",
        "type" : "article",
    },
    {
        "name" : "video",
        "type" : "video",
    },
    {
        "name" : "social_accounts",
        "type" : "social_accounts",
    },
    {
        "name" : "contact",
        "type" : "contact",
    },
    {
        "name" : "message",
        "type" : "message",
    },
];



// mock的API数据，用于示例实际数据
// const mockResponseData = {
//     config: {
//         "websites" : {
//             title: "网站",
//         },
//         "shops" : {
//         },
//     },
//     display: [
//         {
//             "name" : "notice",
//             "type" : "notice",
//         },
//         {
//             "name" : "activity",
//             "type" : "activity",
//         },
//         {
//             "name" : "websites",
//             "type" : "websites",
//         },
//         {
//             "name" : "shops",
//             "type" : "shops",
//         },
//         {
//             "name" : "intro",
//             "type" : "intro",
//         },
//         {
//             "name" : "story",
//             "type" : "story",
//         },
//         {
//             "name" : "members",
//             "type" : "members",
//         },
//         {
//             "name" : "atlases",
//             "type" : "atlases",
//         },
//         {
//             "name" : "honer",
//             "type" : "honer",
//         },
//         {
//             "name" : "course",
//             "type" : "course",
//         },
//         {
//             "name" : "case",
//             "type" : "case",
//         },
//         {
//             "name" : "product",
//             "type" : "product",
//         },
//         {
//             "name" : "partner",
//             "type" : "partner",
//         },
//         {
//             "name" : "company_client",
//             "type" : "company_client",
//         },
//         {
//             "name" : "article",
//             "type" : "article",
//         },
//         {
//             "name" : "video",
//             "type" : "video",
//         },
//         {
//             "name" : "social_accounts",
//             "type" : "social_accounts",
//         },
//         {
//             "name" : "contact",
//             "type" : "contact",
//         },
//         {
//             "name" : "message",
//             "type" : "message",
//         },
//     ],
// }

/**
 * 使用两个字段控制：
 * 1. config: 控制每种类型的模块。按需输出
     * 规格
        "{{name}}" : {
            title: 长度>0则覆盖默认名称
            more_link: 显示「更多」的链接
         }
 * 2. display: 数组，列出需要显示的模块。如果列表中不存在，则不显示。如果display字段不存在，则用默认显示。
    规格：
    {
        "type" : 约定好的模块类型，通过type来控制显示哪一种卡片
        "name" : 模块名称，默认是和type一致。如果有必要，则可以单独选取，这个name用于选择config里面的配置。这样一定程度上，可以存在多个相同type的卡片，但是配置不同。
    }
/**

- notice
- activity
- websites
- shops
- intro
- story
- members
- atlases
- horner
- course
- case
- product
- partner
- company_client
- article
- video
- social_accounts
- contact

// 以下暂无
- faq
- knowledge
- recruit
- media_reports
 */