<template>
    <DomainShopListCardsContainer :list="list"></DomainShopListCardsContainer>
</template>

<script>

// a container contains multiple cards
import DomainShopListCardsContainer from "@/components/cards/domains/DomainShopListCardsContainer.vue";
import axios from "axios";

export default {
	props: {
	},
	name: "DomainShop",
	components: {
        DomainShopListCardsContainer,
	},
	data() {
		return {
            list: [],
		};
	},
    computed: {
        currentWebsiteId () {
            return this.$store.state.websiteId;
        }
    },
    watch: {
        currentWebsiteId (newVal, oldVal) {
            if (newVal && parseInt(newVal) > 0 && newVal != oldVal) {
                this.requestShopData();
            }
        }
    },
    mounted() {
        this.requestShopData();
    },
    methods: {
        requestShopData() {
            const websiteId = this.currentWebsiteId;
            const domain = window.location.host;
            // const websiteId = 474171;
            // const domain = "web3.co.ltd";
            if (websiteId && parseInt(websiteId) > 0) {
                const url = "https://trade.22.cn/api/midian/buylist?guid=" + websiteId + "&domain="+ domain;
                axios.get(url).then((response) => {
                    const responseData = response.data;
                    this.updateUI(responseData);
                }).catch((error)=>{error;});
            }
        },
        updateUI(responseData) {
            if (responseData.data) {
                this.list = responseData.data;
            }
            else {
                this.link = [];
            }
        }
    },
};

</script>

<style scoped>

</style>
