function createURLBarImage(text, backgroundImageURL, fillColor="white") {

    const bgImages = [backgroundImageURL];
    
    return Promise.all(bgImages.map(function(url) {
        // load all images
        return new Promise(function(resolve) {
            var img = new Image();
            img.crossOrigin = "anonymous";
            img.onload = function() { resolve(img); };
            img.src = url;
        });
    })).then(function(bgImages) {

        // console.log(images);

        // fit to height.
        const containerHeight = 120;

        // setup canvas 
        let canvas = document.createElement('canvas');
        canvas.height = containerHeight;

        var ctx = canvas.getContext("2d");

        const font = "normal 72px Arial, 'Helvetica Neue', 'Helvetica', sans-serif";

        // setup font 
        ctx.font = font;
        ctx.fillStyle = fillColor;

        let textSize = ctx.measureText(text);
        // console.log(textSize);

        canvas.width = Math.max(400, textSize.width + 360);

        // draw background to the canvas
        // let bgImage = bgImages[0];
        bgImages.forEach(bgImage => {

            // left
            ctx.drawImage(bgImage, 0, 0, bgImage.width * 0.5, bgImage.height, 0, 0, 200, 120);

            // center
            ctx.drawImage(bgImage, bgImage.width * 0.5-10, 0, 20, bgImage.height, 200, 0, canvas.width - 400, 120);

            // right
            ctx.drawImage(bgImage, bgImage.width * 0.5, 0, bgImage.width * 0.5, bgImage.height, canvas.width - 200, 0, 200, 120);
        });

        // draw text 
        ctx.fillStyle = fillColor;
        ctx.font = font;
        ctx.textBaseline = 'middle';
        ctx.fillText(text, 150, containerHeight/2 + 1);

        // fill colors
        ctx.globalCompositeOperation = 'source-in';
        ctx.fillStyle = fillColor;
        ctx.fillRect(0, 0, canvas.width, canvas.height); 
        
        let dataURL = canvas.toDataURL('image/png');
        return dataURL;
    });
}

export {
    createURLBarImage
}