<template>
	<template v-if="this.content">
		<button type="button" @click="onCopy">
			<slot></slot>
		</button>
	</template>
</template>
<script>
// https://www.npmjs.com/package/vue-clipboard2
export default {
	props: {
		content: String,
	},
	data() {
		return {};
	},
	methods: {
		onCopy: function() {
			if (this.content)
				this.$copyText(this.content).then(
					function(e) {
						alert(e.text + " 已复制");
					},
					function() {}
				);
		},
	},
};
</script>
<style scoped></style>
