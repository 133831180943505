export function getBuiltinDisplayConfigs(websiteId) {

    const websiteIdKey = websiteId.toString();

    const result = builtInDisplayConfigs[websiteIdKey];
    // console.log(builtInDisplayConfigs);

    // console.log(result);

    if (result) { 
        return result;
    }
}

const builtInDisplayConfigs = {
    '4598_' : {
        "config" : {
            "intro": {
                "title" : " ",
            },
            "story" : {
                "title": "公司概况",
            },
            "social_accounts" : {
                "title" : "媒体号",
            },
            "websites" : {
                "title" : "关注我们",
            },
            "atlases" : {
                "title" : "公司风采",
            },
            "video" : {
                "title" : "产品视频",
            },
            "product" : {
                "title" : "产品服务",
            },
            "article" : {
                "title" : "资讯",
            },
            "company_client" : {
                "title" : "",
                // "subtitle" : "累计服务超过1000万家企业客户，大企业都选择，值得信赖！",
            }
        },
        "display" : [    
            {
                "name" : "intro",
                "type" : "intro",
            },
            {
                "name" : "article",
                "type" : "article",
            },
            {
                "name" : "product",
                "type" : "product",
            },
            {
                "name" : "video",
                "type" : "video",
            },
            {
                "name" : "story",
                "type" : "story",
            },
            {
                "name" : "atlases",
                "type" : "atlases",
            },
            {
                "name" : "honer",
                "type" : "honer",
            },
            {
                "name" : "course",
                "type" : "course",
            },

            {
                "name" : "company_client",
                "type" : "company_client",
            },
            {
                "name" : "partner",
                "type" : "partner",
            },
            {
                "name" : "websites",
                "type" : "websites",
            },
            {
                "name" : "social_accounts",
                "type" : "social_accounts",
            },
            // {
            //     "name" : "notice",
            //     "type" : "notice",
            // },
            // {
            //     "name" : "activity",
            //     "type" : "activity",
            // },
            // {
            //     "name" : "case",
            //     "type" : "case",
            // },
            {
                "name" : "contact",
                "type" : "contact",
            },
            {
                "name" : "message",
                "type" : "message",
            },
        ],
    },
    '_394210' : {
        "config" : {

            "intro": {
                // "title" : "关于爱名",
            },
            "story" : {
                "title": "经营理念",
            },
            "social_accounts" : {
                "title" : "媒体号",
            },
            "websites" : {
                "title" : "关注我们",
            },
            "product" : {
                "title" : "",
            }
        },
        "display" : [    
            {
                "name" : "banners",
                "type" : "banners",
            },        
            {
                "name" : "story",
                "type" : "story",
            },
            {
                "name" : "product",
                "type" : "product",
            },
            {
                "name" : "atlases",
                "type" : "atlases",
            },
            {
                "name" : "honer",
                "type" : "honer",
            },
            {
                "name" : "course",
                "type" : "course",
            },
            {
                "name" : "notice",
                "type" : "notice",
            },
            {
                "name" : "activity",
                "type" : "activity",
            },
            {
                "name" : "case",
                "type" : "case",
            },

            {
                "name" : "partner",
                "type" : "partner",
            },
            {
                "name" : "company_client",
                "type" : "company_client",
            },
            {
                "name" : "article",
                "type" : "article",
            },
            {
                "name" : "video",
                "type" : "video",
            },
            {
                "name" : "websites",
                "type" : "websites",
            },
            {
                "name" : "social_accounts",
                "type" : "social_accounts",
            },
            {
                "name" : "contact",
                "type" : "contact",
            },
            {
                "name" : "message",
                "type" : "message",
            },
        ],
    },
    '_371958' : {
        "config": {
            "activity": {
              "title": "厉害活动",
              "subtitle": ""
            },
            "notice": {
              "title": "",
              "subtitle": ""
            },
            "websites": {
              "title": "",
              "subtitle": ""
            },
            "shops": {
              "title": "",
              "subtitle": ""
            },
            "intro": {
              "title": "",
              "subtitle": ""
            },
            "story": {
              "title": "",
              "subtitle": ""
            },
            "members": {
              "title": "",
              "subtitle": ""
            },
            "atlases": {
              "title": "",
              "subtitle": ""
            },
            "honer": {
              "title": "",
              "subtitle": ""
            },
            "course": {
              "title": "",
              "subtitle": ""
            },
            "case": {
              "title": "",
              "subtitle": ""
            },
            "product": {
              "title": "",
              "subtitle": ""
            },
            "partner": {
              "title": "",
              "subtitle": ""
            },
            "company_client": {
              "title": "",
              "subtitle": ""
            },
            "article": {
              "title": "",
              "subtitle": ""
            },
            "video": {
              "title": "",
              "subtitle": ""
            },
            "social_accounts": {
              "title": "",
              "subtitle": ""
            },
            "contact": {
              "title": "",
              "subtitle": ""
            },
            "message": {
              "title": "",
              "subtitle": ""
            }
          },
          "display": [
            {
              "name": "websites",
              "type": "websites",
              "hidden": 0
            },
            {
              "name": "notice",
              "type": "notice",
              "hidden": 0
            },
            {
              "name": "activity",
              "type": "activity",
              "hidden": 0
            },
            {
              "name": "shops",
              "type": "shops",
              "hidden": 0
            },
            {
              "name": "intro",
              "type": "intro",
              "hidden": 0
            },
            {
              "name": "story",
              "type": "story",
              "hidden": 0
            },
            {
              "name": "members",
              "type": "members",
              "hidden": 1
            },
            {
              "name": "atlases",
              "type": "atlases",
              "hidden": 0
            },
            {
              "name": "honer",
              "type": "honer",
              "hidden": 0
            },
            {
              "name": "course",
              "type": "course",
              "hidden": 0
            },
            {
              "name": "case",
              "type": "case",
              "hidden": 0
            },
            {
              "name": "product",
              "type": "product",
              "hidden": 0
            },
            {
              "name": "partner",
              "type": "partner",
              "hidden": 0
            },
            {
              "name": "company_client",
              "type": "company_client",
              "hidden": 0
            },
            {
              "name": "article",
              "type": "article",
              "hidden": 0
            },
            {
              "name": "video",
              "type": "video",
              "hidden": 0
            },
            {
              "name": "social_accounts",
              "type": "social_accounts",
              "hidden": 0
            },
            {
              "name": "message",
              "type": "message",
              "hidden": 0
            },
            {
              "name": "contact",
              "type": "contact",
              "hidden": 0
            },
          ],
    }
};