const punycodeDecoder = require('punycode');


export function prepareDisplayData(res, config = {}) {

	

	let result = {};
	let data = cleanupReponseData(res);

	result.info = prepareInfoData(data);

	// contents
	result.contents = prepareContentsData(data);

	result.info.headerInfo = prepareHeaderInfoData(res, config);
	// console.log(result.info.headerInfo);
	return result;
}

export function prepareDisplayInfoData(res, config = {}) {
	let data = cleanupReponseData(res);

	let info = prepareInfoData(data);
	info.headerInfo = prepareHeaderInfoData(res, config);
	// console.log(result.info.headerInfo);
	return info;
}

export function prepareDisplayContentsData(res, config = {}) {
	let data = cleanupReponseData(res);
	config;
	return prepareContentsData(data);
}


function prepareHeaderInfoData(res, config = {}) {


	let titleInfo = {};
	
	// domain
	let dgDomain = config.domain;
	// console.log(dgDomain)
	// console.log(dgDomain?.length)

	if (!dgDomain || dgDomain.length === 0) {
		try {
			// 1. try location first, if co.ltd domain
			let host = window.location.host;
			host = punycodeDecoder.toUnicode(host);

			if (host?.match(/\.co\.ltd$/i)) {
				dgDomain = host;
			}

			// 2. use domain
			if (!dgDomain || dgDomain?.length == 0) {
				dgDomain = res.info?.domain;
				// let websiteURL = new URL(res.info?.website_url);
				// dgDomain = websiteURL.host;
			}

			// 3. use location as final rescue.
			if (!dgDomain || dgDomain?.length == 0) {
				dgDomain = host;
			}
		} catch (error) {
			//
		}
	}

	dgDomain = capitalizedCoLtdDomainString(dgDomain);
	// if (dgDomain?.match(/\.co.ltd$/i)) {
	// 	dgDomain = dgDomain?.replace(/\.co.ltd$/i, ".Co.Ltd");
	// 	//其他几级、首字母大写
	// }

	titleInfo.dgDomain = dgDomain;

	// logo
	let dgLogoURL = config.logo_url;
	if (!dgLogoURL || dgLogoURL.length == 0) {
		// preferred logo
		dgLogoURL = res.info?.long_logo?.thumbnail;
	}
	if (!dgLogoURL || dgLogoURL.length == 0) {
		dgLogoURL = res.info?.company_logo?.thumbnail;
	}
	titleInfo.dgLogoURL = dgLogoURL;
	return titleInfo;
}

// Prepare Data
// info & members
function prepareInfoData(res) {
	let result = {};

	let info = res.info;
	if (info) {
		// result.domain = "LTD.Co.Ltd";

		// displayname
		let displayName = info.company_short_name?.trim();
		if (!displayName || displayName.length == 0) {
			displayName = info.company_name?.trim();
		}
		if (!displayName || displayName.length == 0) {
			// fallback到网站名称
			const websiteName = info.name?.trim();
			if (websiteName != "未设置名称官微中心") {
				displayName = websiteName;
			}
		}

		if (!displayName || displayName.length == 0) {
			// fallback到Co.Ltd域名（其他域名访问不生效）
			let host = window.location.host;
			host = punycodeDecoder.toUnicode(host);

			if (host?.match(/\.co\.ltd$/i)) {
				displayName = capitalizedCoLtdDomainString(host);
			}
		}

		if (!displayName || displayName.length == 0) {
			displayName = "";
		}

		result.preferredDisplayName = displayName;
		// display Digital Gate name
		// console.log(displayName);

		if (displayName.length > 0) {
			result.preferredDGDisplayName = displayName + "的数字门户";
		} else {
			result.preferredDGDisplayName = "数字门户";
		}

		result.companyShortName = info.company_short_name?.trim();
		result.companyFullName = info.company_name?.trim();
		result.website_id = info.website_id;

		// filter

		// preferred logo
		let logoURL = info.long_logo?.thumbnail;
		if (!logoURL || logoURL.length == 0) {
			logoURL = info.company_logo?.thumbnail;
		}
		if (logoURL?.length > 0) {
			result.preferredDGLogoURL = logoURL;
		}

		result.avatarLogoURL = info.company_logo?.thumbnail;

		result.preferredShareImageURL = result.avatarLogoURL ? result.avatarLogoURL : result.preferredDGLogoURL;
		
		// other infos
		result.slogan = info.slogan;

		// contact
		let contact = {};

		if (info.email?.length > 0) {
			contact.email = info.email;
		}
		if (info.phone?.length > 0) {
			contact.phone = info.phone;
		}
		if (info.contact_name?.length > 0) {
			contact.name = info.contact_name;
		}
		if (info.address?.length > 0) {
			contact.address = info.address;
		}
		try {
			let latitudeValue = parseFloat(info.latitude);
			let longitudeValue = parseFloat(info.longitude);
			if (latitudeValue != 0 || longitudeValue != 0) {
				contact.geoLocation = {
					latitude: latitudeValue,
					longitude: longitudeValue,
				};
			}
		} catch (error) {
			//
		}
		if (Object.keys(contact).length > 0) {
			result.contactInfo = contact;
		}

		// story
		let story = {};
		story.rawContent = info.story?.story_text;
		story.imageURL = info.story?.story_image?.url;
		if (story.rawContent?.length > 0 || story.imageURL?.length > 0) {
		// if (story.rawContent?.length > 0) {
			result.story = story;
		}

		let intro = {};
		intro.rawContent = info.intro?.company_intro;
		intro.imageURL = info.intro?.intro_image?.url;
		if (intro.rawContent?.length > 0 || intro.imageURL?.length > 0) {
			result.intro = intro;
		}
		{
			// banners
			let banners = info?.banners;
	
			let array = [];
			if (banners?.length > 0) {
				banners.forEach((el) => {
					if (el.file?.url && el.file?.is_image == 1) {
						let item = {};
						item.imageURL = el.file?.url;
						item.url = el.file?.url;
						item.id = el.id;
						array.push(item);
					}
				});
				result.banners = array;
			}
		}

		// website
		let website_info = info.website_link_info;
		if (website_info) {

			// console.log(website_info);


			// 1-官网链接 2-小程序码 3-公众号二维码 4-官方商城链接 5-渠道商城链接
			// xcx_type-------1-微信小程序 2-百度智能小程序 3-头条小程序 4-抖音小程序 5-其他
			let officialLinks = {};
			const typeMaps = {
				1: "website",
				2: "miniprogram",
				3: "socialAccount",
				4: "officialShop",
				5: "otherShops",
			};
			const xcxTypeMaps = {
				1: "wechat",
				2: "baidu",
				3: "toutiao",
				4: "douyin",
				5: "other",
			};

			for (let type in website_info) {
				let list = website_info[type];
				// console.log(list)
				let items = [];
				list.forEach((listItemData) => {
					let item = {};
					item.url = listItemData.url;
					item.name = listItemData.name;
					item.qrURL = listItemData.code?.url;
					item.previewImageURL = listItemData.preview_image_url?.url;
					item.id = listItemData.id;

					if (!item.name || item.name.length == 0) {
						if (item.url?.length > 0) {
							// use url
							try {
								let urlURL = new URL(item.url);
								if (urlURL) {
									item.name = urlURL.host;
								}
							} catch (error) {
								item.name = item.url;
							}
						}
					}

					if (type == 2) {
						if(listItemData.xcx_type) {
							let xcxTypeName = xcxTypeMaps[listItemData.xcx_type];
							if (xcxTypeName) {
								item.miniprogramType = xcxTypeName;
							}
							if (!item.name || item.name.length == 0) {
								item.name = "官方小程序";
							}
						}
					}

					if (type == 3) {
						item.platform = "wechat";
						if (!item.name || item.name.length == 0) {
							item.name = "官方公众号";
						}
					}

					if (
						item.name?.length > 0 &&
						(item.url?.length > 0 || item.qrURL?.length > 0)
					) {
						items.push(item);
					}

					// console.log("prepared")

					// 	console.log(item)

					// console.log(items)

					let typeName = typeMaps[type];
					if (typeName && items.length > 0) {
						officialLinks[typeName] = items;
					}

				});
			}

			// console.log(officialLinks);

			result.officialLinks = officialLinks;
			// console.log(result)
		}
	}
	return result;
}

function prepareContentsData(res) {
	// case---案例
	// product---产品
	// article----文章
	// video----视频
	// notice---公告通知
	// activity----最新活动
	// faq---常见问题
	// knowledge-----业务知识
	// recruit----官方招聘
	// media_reports----媒体报道
	let result = {};


	{
		// 处理
		let honors = res.info?.honor;

		let array = [];
		if (honors?.length > 0) {
			honors.forEach((el) => {
				if (el.file?.url) {
					let item = {};
					item.title = el.title;
					item.imageURL = el.file?.thumbnail;
					item.url = el.file?.url;
					item.id = el.id;
					array.push(item);
				}
			});
			result.honorList = array;
		}
	}
	{
		// 处理
		let atlases = res.info?.atlases;

		let array = [];
		if (atlases?.length > 0) {
			atlases.forEach((el) => {
				if (el.file?.url) {
					let item = {};
					item.title = el.title;
					item.imageURL = el.file?.thumbnail;
					item.url = el.file?.url;
					item.id = el.id;
					array.push(item);
				}
			});
			result.albumList = array;
		}
	}

	{
		// 处理
		let history = res.info?.course;

		let array = [];
		if (history?.length > 0) {
			history.forEach((el) => {
				if (el.content?.length > 0) {
					let item = {};
					item.content = el.content;
					// item.dateString = el.started_at;
					// item.dateString = simpleDateExtractor(el.started_at);

					//暂时不显示dd
					item.dateString = simpleDateYYYYMMExtractor(el.started_at);
					
					item.id = el.id;
					if (el.started_at) {
						// safari cannot recognize yyyy-MM-dd format.
						item.dateTimestamp = Date.parse(el.started_at.replace(/-/g, "/"));
					}
					array.push(item);
				}
			});
			result.historyList = array;
			// console.log(array)
		}
	}

	if (res.contents?.case?.data) {
		result.caseList = res.contents?.case?.data;
	}
	if (res.contents?.product?.data) {
		result.productList = res.contents?.product?.data;
		result.productList = result.productList.map(function(item) {
			// console.log(item);
			if (item.defined_link && item.defined_link.length > 0) {
				item.link = item.defined_link;
			}
			else {
				item.link = item.share_url;
			}
		// remove ?card_id=0&is_header=0
			const regex = /&is_header=0/ig;
			if (item.link.match(regex)) {
				item.link = item.link.replace(regex, "");
			}

			return item;
		});

	}

	if (res.contents?.article?.data) {
		result.articleList = res.contents?.article?.data;
		result.articleList = result.articleList.map(contentListItemReplaceFunction);
	}
	if (res.contents?.video?.data) {
		result.videoList = res.contents?.video?.data;
		result.videoList = result.videoList.map(contentListItemReplaceFunction).map(function(item) {
			if (item.thumbnail?.thumbnail) {
				item.previewImageURL = item.thumbnail?.thumbnail;
			}
			else {
				item.previewImageURL = item.file?.thumbnail;
			}
			if (item.previewImageURL) {
				item.previewImageURL = item.previewImageURL.replace(/\?vframe\/png\/offset\/1\/rotate\/auto$/ig, "?vframe/jpg/offset/1/rotate/auto");
			}
			return item;
		});
	}

	if (res.contents?.activity?.data) {
		result.activityList = res.contents?.activity?.data;
		result.activityList = result.activityList.map(contentListItemReplaceFunction);
	}
	if (res.contents?.notice?.data) {
		result.noticeList = res.contents?.notice?.data;
		result.noticeList = result.noticeList.map(contentListItemReplaceFunction);
	}
	if (res.contents?.knowledge?.data) {
		result.knowledgeList = res.contents?.knowledge?.data;
		result.knowledgeList = result.knowledgeList.map(contentListItemReplaceFunction);
	}
	if (res.contents?.recruit?.data) {
		result.recruitList = res.contents?.recruit?.data;
		result.recruitList = result.recruitList.map(contentListItemReplaceFunction);
	}
	if (res.contents?.media_reports?.data) {
		result.mediaReportsList = res.contents?.media_reports?.data;
	}
	if (res.members?.list) {
		result.members = res.members?.list;
	}
	if (res.partner) {
		result.partners = res.partner;
	}
	if (res.company_client) {
		result.company_client = res.company_client;
	}
	
	if (res.social_accounts?.data) {
		result.socialAccountList = prepareSocialData(res);
	}

	return result;
}


function contentListItemReplaceFunction(item) {
	//1. display date: make 2020-09-25 16:16:18 to 2020-09-25
	if (item.published_at) {
		item.display_date = item.published_at.replace(/(\d{4}-\d{1,2}-\d{1,2})\s+\d{1,2}:\d{1,2}:\d{1,2}/i, '$1');
	}
	// 2. abstract and title replace &ldquo; and &rdquo;
	if (item.abstract) {
		item.abstract = item.abstract.replace("&ldquo;", "“");
		item.abstract = item.abstract.replace("&rdquo;", "”");
	}
	// console.log(item.detail_link);
	const regex = /\?card_id=0$/ig;
	if (item.detail_link.match(regex)) {
		item.detail_link = item.detail_link.replace(regex, "");
	}

	return item;
}


function prepareSocialData(res) {
	// media_type:媒体类型 1:微信公众号 2:知乎 3:新浪微博 4:今日头条 5:快手 6:抖音 7:百家号 8:企鹅号 9:大鱼号
	const platformMap = {
		1: "wechat",
		2: "zhihu",
		3: "weibo",
		4: "toutiao",
		5: "kuaishou",
		6: "douyin",
		7: "baidu",
		8: "tencent",
		9: "dayu",
	};
	let list = res.social_accounts?.data;
	let result = [];
	if (list?.length > 0) {
		list.forEach((data) => {
			let item = {};

			if (data.is_relate && data.is_relate > 0 && data.relate_info?.length > 0) {
				item.name = data.media_name;
				item.id = data.id;
				item.official = data.is_official;

				if (data.media_type) {
					item.platform = platformMap[data.media_type];
				}

				try {
					let info = JSON.parse(data.relate_info);
					item.url = info.content_media_url;
					item.qrURL = info.qrcode;
					item.avatarURL = info.content_media_avatar;
				} catch (error) {
					//
				}

				result.push(item);
			}
		});
	}
	return result;
}

// Delete invalid Data

function cleanupReponseData(res, options = {}) {
	for (let prop in res) {
		let val = res[prop];
		if (validateResponseValue(prop, val, options)) {
			// next. and find if has children
			if (typeof val === "object") {
				cleanupReponseData(val, options);
			}
		} else {
			// console.log("will delete");
			// console.log(res[prop]);
			delete res[prop];
		}
	}
	return res;
}

function validateResponseValue(prop, val, options = {}) {
	// cleanup invalid no-image url
	// delete url/thumbnail= https://wei.ltd.com/assets/images/no-image.jpg

	if (options) {
		/* */
	}
	if (prop == "url" || prop == "thumbnail") {
		// if (val == "https://wei.ltd.com/assets/images/no-image.jpg") {
			if (val == 'https://wei.ltd.com/assets/images/no-image.jpg' || val == 'https://static.xcx.gw66.vip/assets/images/no-image.jpg') {
			return false;
		}
	}
	return true;
}

export function capitalizedCoLtdDomainString(string) {
	// only .co.ltd
	if (string?.match(/\.co\.ltd$/i)) {
		return string.split('.').map(capitalizedString).join('.');
	}
	// 'ceshi.co.ltd'.split('.').map(x => capitalizedString(x)).join('.')
	return string;
}

function capitalizedString(string) {
	if (!string || string.length == 0) { return ''; }
	else if (string.match(/^www$/i)) {
		// 特殊处理WWW
		return string.toLowerCase();
	}
	return string.charAt(0).toUpperCase() + string.slice(1);
}

// function simpleDateExtractor(string) {
// 	let dateString = string;

// 	const regex = /(\d{4}-\d{2}-\d{2})\s+\d{2}:\d{2}:\d{2}/i;
// 	dateString = dateString.replace(regex, "$1");
// 	return dateString;
// }

function simpleDateYYYYMMExtractor(string) {
	let dateString = string;

	const regex = /(\d{4}-\d{2})-\d{2}\s+\d{2}:\d{2}:\d{2}/i;
	dateString = dateString.replace(regex, "$1");
	return dateString;
}

// import dateFormat from "dateformat";

// function parseHistoryDate(string, format) {
// 	let dateString = string
// 	try {
// 		if (string) {
// 			// let date = new Date(Date.parse(string));
// 			dateString = dateFormat(string, format);
// 		}

// 		// console.log(dateString)

// 	} catch (error) {
// 		//
// 		// console.log('parse error')
// 		// console.log(error)
// 		const regex = /(\d{4}-\d{2}-\d{2})\s+\d{2}:\d{2}:\d{2}/i;
// 		dateString = dateString.replace(regex, '$1')
// 	}
// 	return dateString;
// }
