<template>
	<CardContainer :title="title" :subtitle="subtitle" :showsAction="false">

		<div class="list hidden md:block" v-if="this.list?.length > 0" >
			<template v-for="item in this.list.slice(0, 1)" :key="item.id">
				<a class="item" :href="item.detail_link" target="_blank">
					<div class="title">{{ item.title }}</div>
				</a>
			</template>
		</div>


		<div class="list md:hidden" v-if="this.list?.length > 0">
			<template v-for="item in list.slice(0, 1)" :key="item.id">
				<a class="item" :href="item.detail_link" target="_blank">
					<div v-if="item.thumbnail?.url?.length > 0" class="image">
						<img :src="item.thumbnail?.url" />
					</div>
					<div class="title">{{ item.title }}</div>
				</a>
			</template>
		</div>
	</CardContainer>
</template>

<script>
import CardContainer from "@/components/cards/CardContainer.vue";

export default {
	props: {
		list: Object,
		title: { type: String, default: "活动"},
		subtitle: { type: String},
	},
	name: "ActivityCard",
	components: {
		CardContainer,
	},
	data() {
		return {
			// title: "活动",
		};
	},
};
</script>

<style scoped>
/* 活动 */
.item {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 4px;
}

.item .image {
	max-height: 300px;
	overflow: hidden;
	padding: 0;
}
.item > .image > img {
	object-fit: cover;
	object-position: center;
	width: 100%;
	height: 100%;
	margin: 0;
	border-radius: 3px;
}

.item .title {
	font-weight: 500;
	color: #555;
}
.item .title {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	margin-top: 4px;
	font-size: 14px;
}

@media (min-width: 768px) {

	.item .title {
		font-size: 15px;
	}
}

</style>
