<template>
	<a class="item-container flex-row" @click="handleClick">
		<div class="image"><img :src="imageURL" /></div>
		<div class="info-container flex-column">
			<div class="name-container flex-row">
				<div class="name">{{ name }}</div>
				<div class="duty">{{ duty }}</div>
			</div>
			<div class="intro line-clamp-3">{{ displayIntro }}</div>
		</div>
	</a>
</template>

<script>
import textVersion from "textversionjs";
import { decode } from "html-entities";
import { $vfm } from "vue-final-modal";

export default {
	props: {
		duty: String,
		name: String,
		imageURL: String,
		intro: String,
		QRCodeURL: String,
	},
	name: "MemberItem",
	components: {},
	data() {
		return {};
	},
	computed: {
		displayIntro: function() {
			// console.log(this.intro);
			let text = this.intro;
				try {
					// console.log(text)
					// let converted = textVersion(text);

					const styleConfig = {
						linkProcess: function(href, linkText){
							return linkText;
						}
					};

					let converted = textVersion(text, styleConfig);

					text = decode(converted);
														// console.log(text)

				} catch (error) {
					// console.log(error)
				}
			// }
			return text;
		},
		qrurl: function() {
			if (this.QRCodeURL && !this.QRCodeURL.startsWith("http")) {
				return "http://" + this.QRCodeURL
			}
			return this.QRCodeURL;
		},
	},
	methods: {
		handleClick: function() {
			// console.log(this.qrurl);
			if (this.qrurl?.length > 0) {
				$vfm.show("cardcodemodal", {
					QRImageURL: this.qrurl,
					title: this.name,
					duty: this.duty
				});
			}
		},
	},
};
</script>

<style scoped>
.item-container {
	height: 100px;
}

.image {
	height: 88px;
	width: 88px;

	flex-grow: 0;
	flex-shrink: 0;

	border-radius: 8px;
	overflow: hidden;
	/* placeholder */
	/* background-color: #f0f0f0;
	border-radius: 5px; */
}

.image > img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.info-container {
	height: 100%;
	margin-left: 15px;
	flex-grow: 1;
	flex-shrink: 1;
}

.name-container {
	margin-top: 4px;
	margin-bottom: 4px;
	align-items: center;
}

.name-container .name {
	font-size: 15px;
	font-weight: 500;
	color: #424a5e;
	flex-grow: 0;
	flex-shrink: 0;
}

.name-container .duty {
	font-size: 14px;
	font-weight: 400;
	color: #909399;
	margin-left: 6px;
}

.intro {
	font-size: 13px;
	font-weight: 400;
	color: #606266;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.flex-row {
	display: flex;
	flex-direction: row;
}
img[lazy=loading],img[lazy=loaded],img[lazy=error] {
	background-color: rgba(250,250,250,20);
}
</style>
