<template>
<div class="intro-embed"> 
  <iframe src="https://www.22.cn/coltd/?noh=1">
  </iframe>

</div>
</template>

<script>

export default {
  name: 'CoLtdIntroEmbed',
  data () {
    return {
    }
  },
  mounted () {
      
	if (process.env.NODE_ENV == "development") {
				// console.log(process.env.NODE_ENV);
			} else {
				// 只有当生产环境才发送
				const scriptLabel = document.createElement("script");
				scriptLabel.innerText = `_ga.url = 'https://wei.ltd.com/analyst';_ga.params['wi'] = '3';_ga.params['ui'] ='0';_ga.init(760);`;
				document.querySelector("body").appendChild(scriptLabel);
			}
  }
}
</script>


<style scoped>

iframe {
    display: block;       /* iframes are inline by default */
    background: white;
    border: none;         /* Reset default border */
    height: 100vh;        /* Viewport-relative units */
    width: 100vw;
}

</style>
