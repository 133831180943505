<template>
	<CardContainer :title="title" :subtitle="subtitle" :showsAction="false">
		<div class="list">
			<div
				class="grid grid-cols-4 md:gap-4 sm:gap-4 lg:gap-8 text-center md:grid-cols-4 lg:grid-cols-6 sm:grid-cols-2 md:px-2"
			>
				<template v-for="item in this.list?.slice(0, 12)" :key="item.id">
					<template v-if="item.url?.length > 0">
					<a
						:href="item.url"
						target="_blank"
						class="image-container flex items-center justify-center p-1 rounded-sm  overflow-hidden group relative"
					>
						<img
							:src="item.file?.thumbnail"
							:alt="item.name"
							class="block object-contain h-full w-full"
						/>

					<div v-if="item.name?.length > 0" class="absolute inset-0 bg-white bg-opacity-90 opacity-0 group-hover:opacity-100 duration-200">
                  <p class="text-md font-medium grid place-items-center h-full text-black text-center">
					{{item.name}}
                  </p>
                </div>

					</a>
					</template>
					<template v-else>
					<div
						v-if="!item.url || item.url?.length == 0"
						class="image-container flex items-center justify-center p-1 rounded-sm  overflow-hidden group relative"
					>
						<img
							:src="item.file?.thumbnail"
							:alt="item.name"
							class="block object-contain h-full w-full"
						/>

						<div v-if="item.name?.length > 0" class="absolute inset-0 bg-white bg-opacity-90 opacity-0 group-hover:opacity-100 duration-200">
						<p class="text-md font-medium grid place-items-center h-full text-black text-center">
							{{item.name}}
						</p>
						</div>

					</div>
					</template>
				</template>
			</div>
		</div>
	</CardContainer>
</template>

<script>
import CardContainer from "@/components/cards/CardContainer.vue";

export default {
	props: {
		list: Object,
		title: { type: String, default: "典型客户"},
		subtitle: { type: String},
	},
	name: "ClientsCard",
	components: {
		CardContainer,
	},
	data() {
		return {
		};
	},
};
</script>

<style scoped>
.image-container {
height: 88px;
}
@media (hover:hover) {
    .image-container:hover {
		background: #fcfcfc;
    }
}

</style>
