<template>
	<CardContainer :title="title" :subtitle="subtitle" :showsAction="false">
		<div class="content">
			<template v-for="item in this.officialShops?.slice(0,1)" :key="item.id">
			<WebsitePreviewItem 
				:name="item.name"
				:url="item.url"
				:previewImageURL="item.previewImageURL"
			></WebsitePreviewItem>
			</template>
			<SubContentContainer title="渠道商城" v-if="this.otherShops?.length > 0">
				<div class="website-items">
					<template v-for="item in this.otherShops" :key="item.id">
						<StoreListItem
							:name="item.name"
							:url="item.url"
						></StoreListItem>
					</template>
				</div>
			</SubContentContainer>
		</div>
	</CardContainer>
</template>

<script>
import CardContainer from "@/components/cards/CardContainer.vue";
import SubContentContainer from "@/components/cards/SubContentContainer.vue";
import StoreListItem from "@/components/cards/subcards/StoreListItem.vue";
import WebsitePreviewItem from "@/components/cards/subcards/WebsitePreviewItem.vue";

export default {
	props: {
		title: { type: String, default: "官方商城"},
		subtitle: { type: String},
		officialShops: Object,
		otherShops: Object,
	},
	name: "StoresCard",
	components: {
		CardContainer,
		StoreListItem,
		WebsitePreviewItem,
		SubContentContainer,
	},
	data() {
		return {
			// title: "官方商城",
		};
	},
};
</script>

<style scoped>
.content > * {
	margin: 20px 0;
}

.content > *:last-child {
	margin-bottom: 0;
}

.content > *:first-child {
	margin-top: 0;
}

.website-items > * {
	margin: 12px 0;
}

.website-items > *:last-child {
	margin-bottom: 0;
}
.website-items > *:first-child {
	margin-top: 0;
}
</style>
