<template>
	<template v-if="showsIntro">
		<!-- <CoLtdIntroPage></CoLtdIntroPage> -->
		<CoLtdIntroEmbed></CoLtdIntroEmbed>
	</template>
	<!-- <template v-else-if="showsConfigGuide">
		<ConfigGuidePage :domain="guideDomain"></ConfigGuidePage>
	</template> -->
	<template v-else>
		<router-view v-if="load"/>
	</template>

</template>
<script>

import { validateURLStringIfNeedsRedirect, checkIfWWWCOLTDDomain, getCoLtdDomain } from "@/utils/utilities.js";
// import CoLtdIntroPage from "@/components/intro/CoLtdIntroPage.vue";
import CoLtdIntroEmbed from "@/components/intro/CoLtdIntroEmbed.vue";

// import ConfigGuidePage from "@/components/intro/ConfigGuide.vue";

export default {
	data() {
		return {
			load: false,
			showsIntro: false,
			showsConfigGuide: false,
			guideDomain: "",
		};
	},
	created () {
		const urlString = window.location.href;
		this.guideDomain = getCoLtdDomain(urlString);
		const testShowConfig = false;
		const testShowIntro = false;
		if (testShowConfig) {
			// 测试
			this.showsConfigGuide = true;
		}
		else if (testShowIntro) {
			this.showsIntro = true;
		}
		else {
		// if host matches www.xxx.co.ltd, remove www and jump.
			if (checkIfWWWCOLTDDomain(urlString)) {
				this.showsIntro = true;
			}
			else {
				let redirect = validateURLStringIfNeedsRedirect(window.location.href);

				// console.log(redirect);

				// redirect = validateURLStringIfNeedsRedirect("https://www.aiming.co.ltd");
				// console.log(redirect);

				if (redirect.redirect) {
					window.location.replace(redirect.urlString);
				}
				else {
					this.load = true;
				}
			}
		}
	},
	components: {
		// CoLtdIntroPage,
		CoLtdIntroEmbed,
		// ConfigGuidePage,
	},
};
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* text-align: center; */
	color: #2c3e50;
	/* background: white; */
}

#nav {
	padding: 30px;
}

#nav a {
	font-weight: bold;
	color: #2c3e50;
}

#nav a.router-link-exact-active {
	color: #42b983;
}
</style>
