const punycodeDecoder = require('punycode');

// return { redirect : true, urlString : url }
export function getDisplayDomain() {

    let host = window.location.host;
    host = punycodeDecoder.toUnicode(host);
    return host;
}

export function checkIfWWWCOLTDDomain(urlString) {
    let aURL = new URL(urlString);
    let host = aURL?.host;
    if (host) {
        // 2. www.co.ltd、co.ltd -> https://www.22.cn/CoLtd/?s=www.co.ltd

        const matchWWWCOLTD = host?.match(/^(www\.)?co\.ltd$/i);
        if (matchWWWCOLTD) {
            return true;
        }
    }
    return false;
}


export function getCoLtdDomain(urlString) {
    let aURL = new URL(urlString);
    let host = aURL?.host;

    // decode中文域名
    host = punycodeDecoder.toUnicode(host);

    // // const decoded = punycodeDecoder.decode('Xn--dkrt1lj2u.Co.Ltd');
    // console.log(punycodeDecoder.toUnicode('host'));
    // console.log(punycodeDecoder.toUnicode('xn--maana-pta.com'));
    
    // console.log(punycodeDecoder.toUnicode('xn--0zwm56d.com'));
    // console.log(punycodeDecoder.toASCII('大湾区.co.ltd'));

    // Xn--dkrt1lj2u.Co.Ltd
    if (host) {
        // 2. www.co.ltd、co.ltd -> https://www.22.cn/CoLtd/?s=www.co.ltd

        const matchCOLTD = host?.match(/^(.+)\.co\.ltd$/i);
        if (matchCOLTD) {
            return host.replace(/^(.+)\.co\.ltd$/i, "$1.Co.Ltd");
        }
    }
}

export function validateWWWCOLTDURLStringIfNeedsRedirect(urlString) {
    if (checkIfWWWCOLTDDomain(urlString)) {
        return {redirect : true, urlString : "https://www.22.cn/CoLtd/?s=www.co.ltd"};
    }
    return {redirect : false, urlString : urlString};
}

export function validateURLStringIfNeedsRedirect(urlString) {
    let aURL = new URL(urlString);
    let host = aURL?.host;
    if (host) {

        // 1. www.*.co.ltd -> *.co.ltd
        let components = host?.match(/www\.(.+\.co\.ltd)$/i);

        if (components && components.length > 1) {
            host = components[1];
            aURL.host = host;
            return {redirect : true, urlString : aURL.toString()};
        }
    }
    return {redirect : false, urlString : urlString};
}