<template>
	<a class="item-container" :href="url" target="_blank">
        <h1 class="title"><span clas="domain">{{ domain }}</span><span v-if="domainType" class="domain-type">{{ domainType }}</span></h1>
        <p class="desc">{{ desc }}</p>
        <div class="detail">
        <div class="price-section"><span class="price-type">{{ priceType }}: </span><span class="price">{{ price }}</span></div>
        <p class="date">剩余时间: {{ lefttime }}</p>
        </div>
	</a>
</template>

<script>
/**
 * 
 *           "domain": "sannong.Co.Ltd数字门户",
          "price": "30,000",
          "desc": "三农",
          "lefttime": "363天8时",
          "url": "https://am.22.cn/ykj/chujia_30391177.html"
 */
export default {
	props: {
        domain: String,
        domainType: String,
        price: String,
        desc: String,
        lefttime: String,
        url: String,  
        priceType: {
            type: String,
            default: "价格",
        },
	},
	name: "DomainShopItem",
	components: {},
	data() {
		return {};
	},
};
</script>

<style scoped>
.title {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    color: #3366CC;
    margin-bottom: 4px;
}

.domain-type {
    font-weight: normal;
    font-size: 12px;
    margin-left: 6px;
}

.desc {
        font-size: 13px;
    color: #555;
}
.price-section {
    font-size: 14px;
    line-height: 15px;
}
.date {
    font-size: 14px;
    line-height: 15px;
}
.price {
    font-weight: bold;
    color: #ff7300
}
.desc {
    font-size: 13px;
    color: #555;
}

.date {
    margin-top: 6px;
}

.detail {
position: absolute;
bottom: 20px;
left: 20px;
right: 20px;
}

.item-container {
    position: relative;
	display: block;
    height: 160px;
    background: white;
    padding: 20px;

    transition: transform 0.15s ease-in, 
    box-shadow 0.15s ease-in, 
}

/* normal state */
.item-container {
    border: 1px dashed #ededed;
    border-radius: 0;
    box-shadow: none;
    z-index: 1;
    transform: scale(1,1);
}

/* hover state */

.item-container:hover {
    border: none;
    border-color: rgba(255, 255, 255, 0);
    border-radius: 3px;
    box-shadow: 0 6px 12px 0 rgba(0,0,0,0.12);
    z-index: 10;
    /* transform: scale(1.02,1.02); */
}

@media screen and (max-width: 568px){

    .detail {
        bottom: 12px;
        left: 12px;
        right: 12px;
    }

    .item-container {
        padding: 12px;
    }

    .title {
        font-size: 16px;
        line-height: 18px;
    }

    .domain-type {
        font-size: 11px;
    }
}


</style>
