<template>
	<CardContainer :title="title" :subtitle="subtitle" :showsAction="false">

		<!-- <div class="pt-2 grid gap-2 grid-cols-1 md:grid-cols-2 md:gap-x-8 items-start"> -->
		<div class="list pt-1 grid gap-2 grid-cols-1 lg:grid-cols-2 md:gap-x-8 items-start">
			<template v-for="item in this.list" :key="item.id">
				<SocialMediaListItem
					:name="item.name"
					:url="item.url"
					:QRCodeURL="item.qrURL"
					:platform="item.platform"
                    :avatarURL="item.avatarURL"
				></SocialMediaListItem>
			</template>
		</div>
	</CardContainer>
</template>

<script>
import CardContainer from "@/components/cards/CardContainer.vue";
import SocialMediaListItem from "@/components/cards/subcards/SocialMediaListItem.vue";

export default {
    props: {
		title: { type: String, default: "关注我"},
		subtitle: { type: String},
        list: Object
    },
	name: "SocialMediaCard",
	components: {
		CardContainer,
		SocialMediaListItem,
	},
	
	data() {
		return {
			// title: "关注我",
		};
	},
};
</script>

<style scoped>

.list > * {
	margin: 6px 0;
}

.list > *:last-child {
	margin-bottom: 0;
}
.list > *:first-child {
	margin-top: 0;
}
</style>
