<template>
<div class="name-info-container">
    <div class="title" v-if="title?.length > 0">{{title}}</div>
    <div class="subtitle" v-if="subtitle?.length > 0">{{subtitle}}</div>
    <div class="slogan" v-if="slogan?.length > 0">{{slogan}}</div>
</div>
</template>

<script>
export default {
    props: {
        title : String,
        subtitle : String,
        slogan : String
    },
    name: 'HeaderInfo',
    data () {
        return {
        }
    },
    computed: {
        companyDisplaySlogan: function () {
            // todo, trim
            return this.companySlogan;
        }
    },
    components: {

    },
}
</script>

<style scoped>

.name-info-container {
    padding: var(--element-padding-normal);
}
.name-info-container * {
    line-height: 150%;;
}

.title {
    color: var(--theme-header-text-color);
    font-size: 32px;
    font-weight: 600;
    line-height: 110%;
    margin-bottom: 16px;
}

.subtitle {
    color: var(--theme-header-text-color);
    font-size: 20px;
    font-weight: 400;
    margin-bottom:18px;
}

.slogan {
    color: var(--theme-header-text-color);
    opacity: 0.97;
    font-size: 14px;
    font-weight: 500;
    white-space: pre-line;
}

</style>