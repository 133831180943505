<template>
	<div class="main-container">
		<img class="share-image" :src="info?.preferredShareImageURL" v-if="info?.preferredDGLogoURL"/>
		<Header :info="info"></Header>
		<div class="card-container mb-12 mx-3 md:mx-4">
			<CardList :info="info" :contents="contents" :displayConfig="displayConfig"></CardList>
		</div>
		<Copyright
			:name="info?.preferredDGDisplayName"
			:domain="info?.headerInfo?.dgDomain"
			:logoURL="info?.avatarLogoURL"
		></Copyright>
		<Statistic></Statistic>
		<Powerby name="营销枢纽"></Powerby>
	</div>
</template>

<script>
import Header from "@/components/header/Header.vue";
import CardList from "@/components/container/CardList.vue";
import Copyright from "@/components/others/Copyright.vue";
import Powerby from "@/components/others/Powerby.vue";
import Statistic from "@/components/others/Statistic.vue";

export default {
	props: {
		displayData: Object,
		displayConfig: Object,
	},
	// data() {
	// 	return {
	//     domain: "LTD.Co.Ltd",
	//     shortName:"LTD营销SaaS",
	//     companyName: "杭州乐通达网络有限公司",
	//     logoURL: "https://uploadfile.xcx.gw66.vip/uploadfile/image/0/16/222/2021-06/16227869087475.png",
	//   };
	// },
	// data () {
	//   return {
	//   infodata : this.websiteinfo
	//   }
	// },
	computed: {
		info() {
			// console.log(this.displayData?.info?.preferredDGLogoURL);
			return this.displayData?.info;
		},
		contents() {
			// console.log(this.websiteinfo.info.company_logo.url);
			return this.displayData?.contents;
		},
	},
	name: "MainPageContainer",
	components: {
		Header,
		CardList,
		Copyright,
		Powerby,
		Statistic,
	},
};
</script>

<style scoped>
.main-container {
	width: 100%;
	height: 100%;
}

.card-container {
	margin-top: calc(var(--element-spacing-normal) - 120px);
}
.main-container {
	padding-bottom: 16px;
	position: relative;
}
.share-image {
	position: absolute;
	opacity: 0.01;
	width: 200px;
	height: auto;
	left: -200px;
}

</style>
