<template>
	<div class="content-website-list">
		<!-- <div class="content-website-item">
			<div class="website-website-url-bar">
				<div class="url-bar-background"></div>
				<button class="website-url-container" @click="handleURLClick">
					<div class="website-url-ssl"></div>
					<div class="website-url-content">{{ this.displayName }}</div>
				</button>
				<div class="website-action-container">
					<CopyButton :content="this.targetURL">
						<span class="website-action-copy">复制</span>
					</CopyButton>

					<div class="website-action-separator"></div>
					<button class="website-action-qr" @click="handleQRClick"></button>
				</div>
			</div>
		</div> -->
		<div class="content-website-item">
			<div class="url-bar">
				<div class="url-bar-background"></div>
				<div class="url-bar-ssl"></div>
				<a class="url-bar-title" :href="this.targetURL" target="__blank">{{ this.displayName }}</a>
				<div class="url-bar-action">
					<CopyButton class="action-button" :content="this.targetURL">
						<span>复制</span>
					</CopyButton>
					<div class="website-action-separator"></div>
					<button class="website-action-qr" @click="handleQRClick"></button>
				</div>
			</div>
		</div>
		<a
			v-if="shouldShowPreview"
			class="website-website-info-container flex flex-row gap-x-0"
			:href="this.targetURL"
			target="_blank"
		>
			<div class="website-preview-container" v-if="showsPreview">
				<div class="website-preview">
					<iframe :src="this.targetURL" frameborder="0"></iframe>
				</div>
			</div>
			<div
				class="website-image-preview-container"
				v-if="showsImagePreview"
			>
				<div class="image-preview">
					<img class="image-preview-img" :src="displayPreviewImageURL" />
				</div>
			</div>
		</a>
	</div>
</template>

<script>
import CopyButton from "@/components/cards/functional/CopyButton.vue";
// import QRContent from "@/components/cards/functional/QRContentView.vue";

import { $vfm } from "vue-final-modal";
// && this.previewImageURL?.length > 0

export default {
	props: {
		typeName: String,
		name: String,
		url: String,
		qrurl: String,
		previewImageURL: String,
		showsPreview: {
			type: Boolean,
			default: false,
		},
		showsImagePreview: {
			type: Boolean,
			default: true,
		},
	},
	name: "WebsitePreviewItem",
	components: {
		CopyButton,
		// QRContent,
	},
	data() {
		return {};
	},
	computed: {
		displayPreviewImageURL: function() {
			// return "https://lh3.googleusercontent.com/ElDH_L7Atld4xTRjDs0PKlG6dIOenljL9J97Xbys5Rk7h_BL1UlOtBRWFuGn9eoYDRBHamh-TH9ZYjsy2i0MRknlKQ=w640-h400-e365-rj-sc0x00ffffff";
			return this.previewImageURL;
		},
		shouldShowPreview: function() {
			return (
				this.showsPreview ||
				(this.showsImagePreview && this.displayPreviewImageURL?.length > 0)
			);
		},
		targetURL: function() {
			if (this.url && !this.url.startsWith("http")) {
				return "http://" + this.url;
			}
			return this.url;
		},
		displayName: function() {
			// 1. use name; 2. use host; 3. copy url
			return this.name;
		},
	},
	methods: {
		handleQRClick: function() {
			$vfm.show("qrmodal", {
				QRContent: this.targetURL,
				QRImageURL: this.qrurl,
				title: this.name,
			});
		},
		handleURLClick: function() {
			window.open(this.targetURL, "_blank");
		},
	},
};
</script>

<style scoped>
.content-website-item {
	margin: 12px 0;
}

.website-website-url-bar {
	background: rgba(0, 0, 0, 0);
	height: 32px;
	width: auto;
	border-radius: 22px;
	border: solid var(--theme-text-color);
	border-width: 1px;
	color: var(--theme-text-color);

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0 16px;

	position: relative;
	overflow: hidden;
}

.website-url-ssl {
	width: 11px;
	height: 16px;
	background-color: var(--theme-text-color);
	margin-right: 12px;
	mask-image: url("~@/assets/images/icon-ssl.png");
	-webkit-mask-image: url("~@/assets/images/icon-ssl.png");
	mask-size: contain;
    -webkit-mask-size: contain;
	mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
}

.website-website-url-bar .website-url-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.url-bar-background {
	/* z-index: -1; */
	pointer-events: none;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	background-color: var(--theme-text-color);
	opacity: 0.05;
}

.website-url-content {
	font-size: 16px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	font-weight: 500;
}

.website-website-url-bar .website-action-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 14px;
}

/* .website-action-copy {
	display: block;
} */

.website-action-separator {
	width: 1px;
	background-color: var(--theme-text-color);
	opacity: 0.5;
	height: 14px;
	margin: 8px;
}

.website-action-qr {
	flex-grow: 0;
	flex-shrink: 0;
	height: 14px;
	width: 14px;
	mask-image: url("~@/assets/images/icon-qr.png");
	-webkit-mask-image: url("~@/assets/images/icon-qr.png");
	mask-size: contain;
    -webkit-mask-size: contain;
	background-color: var(--theme-text-color);
}

.website-website-info-container {
	margin-top: 12px;
	display: flex;
}

.website-code-container {
	background-color: #f0f0f0;
	border-radius: 4;
	width: 90px;
	height: 90px;
	border-radius: 45px;
}

.website-preview-container,
.website-image-preview-container {
	width: 200px;
	height: 120px;
	overflow: hidden;
	border-radius: 5px;
	margin-right: 20px;
}

.image-preview {
	width: 100%;
	height: 100%;
	background-color: #f0f0f0;
}
.image-preview img.image-preview-img {
	object-fit: cover;
	object-position: top center;
	height: 100%;
	width: 100%;
}

.website-preview {
	pointer-events: none;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

.website-preview iframe {
	width: 1200px;
	height: 720px;
}

.website-preview {
	position: relative;
	-ms-zoom: calc(1 / 6);
	-moz-transform: scale(calc(1 / 6));
	-moz-transform-origin: 0 0;
	-o-transform: scale(calc(1 / 6));
	-o-transform-origin: 0 0;
	-webkit-transform: scale(calc(1 / 6));
	-webkit-transform-origin: 0 0;
	-webkit-backface-visibility: hidden;
}

/* updated url-bar */
.url-bar {

	height: 32px;
	width: auto;
	border-radius: 22px;
	border: solid var(--theme-text-color);
	border-width: 1px;
	color: var(--theme-text-color);

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0 16px;

	position: relative;
	overflow: hidden;
}

.url-bar-ssl {
	width: 11px;
	height: 16px;
	background-color: var(--theme-text-color);

	margin-right: 8px;
	mask-image: url("~@/assets/images/icon-ssl.png");
	-webkit-mask-image: url("~@/assets/images/icon-ssl.png");
	mask-size: contain;
    -webkit-mask-size: contain;
	mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
}

.url-bar-title {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow:hidden;
	font-size: 16px;
	font-weight: 500;
}

.url-bar-action {
	margin-left: 4px;
		display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
/* flex */

.url-bar-title {
	flex-grow: 4;
	flex-shrink: 4;
}

.url-bar-ssl {
	flex-shrink: 0;
	flex-grow: 0;
}

.url-bar-action {
	flex-shrink: 0;
	flex-grow: 0;
}
.url-bar-action > * {
	flex-shrink: 0;
	flex-grow: 0;
}

/* .action-copy, .action-qr {
	flex-shrink: 0;
	flex-grow: 0;
} */

</style>
