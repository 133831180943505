<template>
<div v-if="displayName.length>0" class="content"><a :href="supportURL" target="_blank">Powered by {{displayName}}</a></div>
</template>

<script>
export default {
  name: 'Powerby',
  props: {
      name: {
          type: String,
          default: "LTD"
      },
      supportURL: {
        type: String,
        default: "https://www.ltd.com"
      }
  },
  computed : {
      displayName : function () {
        return this.name.trim();
      }
  }
}
</script>

<style scoped>
.content {
    font-size: 13px;
    opacity: 0.2;
    text-align: center;
    padding: 12px;
}
</style>
