<template>
	<div class="item-container">
		<button class="icon" @click="handleTap">
			<img v-if="iconURL && iconURL.length > 0" :src="iconURL" />
		</button>
		<div class="info-container">
			<button @click="handleTap">
				<div class="name">{{ name }}</div>
			</button>
			<button
				v-if="showsQRAction == true"
				class="qr"
				@click="handleClick"
			></button>
		</div>
	</div>
</template>

<script>
import { $vfm } from "vue-final-modal";

export default {
	props: {
		name: String,
		QRCodeURL: String,
		url: String,
		platform: String,
        avatarURL: String,
	},
	name: "SocialMediaListItem",
	components: {},
	data() {
		return {
			icons: {
				baidu: require("@/assets/images/SocialMedias/social_media_baidu.png"),
				wechat: require("@/assets/images/SocialMedias/social_media_wechat.png"),
				douyin: require("@/assets/images/SocialMedias/social_media_douyin.png"),
				dayu: require("@/assets/images/SocialMedias/social_media_dayu.png"),
				toutiao: require("@/assets/images/SocialMedias/social_media_toutiao.png"),
				weibo: require("@/assets/images/SocialMedias/social_media_weibo.png"),
				kuaishou: require("@/assets/images/SocialMedias/social_media_kuaishou.png"),
				tencent: require("@/assets/images/SocialMedias/social_media_tencent.png"),
				zhihu: require("@/assets/images/SocialMedias/social_media_zhihu.png"),
				default: require("@/assets/images/SocialMedias/social_media_default.png"),
			},
		};
	},
	computed: {
		iconURL: function() {
			let url = "";
			if (this.platform) {
				url = this.icons[this.platform];
			}
			if (url && url.length > 0) {
				return url;
			}
			return this.icons["default"];
		},
		qrurl: function() {
			return this.QRCodeURL;
		},
		showsQRAction: function() {
			return (
				(this.QRCodeURL && this.QRCodeURL.length > 0) ||
				(this.url && this.url.length > 0)
			);
		},
	},
	methods: {
		handleTap: function() {
			/**
			 * 1. 包含链接，直接跳转
			 * 2. 没有包含，但是有QR，显示QR行为
			 * 3. 复制文件名?
			 *
			 */
			if (this.url && this.url.length > 0) {
				window.open(this.url, "_blank");
			} else if (this.showsQRAction) {
				this.showQR();
			}
		},
		handleClick: function() {
			if (this.showsQRAction) {
				this.showQR();
			}
		},
		showQR: function() {
			$vfm.show("qrmodal", {
				QRContent: this.url,
				QRImageURL: this.QRCodeURL,
				title: this.name,
                avatarURL: this.avatarURL
			});
		},
	},
};
</script>

<style scoped>
.item-container {
	display: flex;
	height: 44px;
	align-items: center;
}

.icon {
	height: 36px;
	width: 36px;

	flex-grow: 0;
	flex-shrink: 0;

	/* placeholder */
	background-color: #f9f9f9;
	border-radius: 18px;
}
.icon > img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.info-container {
	height: 36px;

	margin-left: 12px;
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	background-color: #f9f9f9;
	border-radius: 18px;
	align-items: center;
	justify-content: space-between;
}

.info-container .name {
	flex-grow: 1;
	flex-shrink: 1;
	margin-left: 16px;
	font-size: 15px;
	color: #333;
	font-weight: 500;

	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.info-container .qr {
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: 12px;
	height: 14px;
	width: 14px;
	mask-image: url("~@/assets/images/icon-qr.png");
	-webkit-mask-image: url("~@/assets/images/icon-qr.png");
	mask-size: contain;
	-webkit-mask-size: contain;
	background: #888;
}
</style>
