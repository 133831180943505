<template>
	<CardContainer :title="title" :subtitle="subtitle" :showsAction="false">
		<div class="list">
			<div
				class="grid gap-3 row-gap-3 grid-cols-2 md:grid-cols-3 md:row-gap-4 md:gap-4"
			>
				<template v-for="item in this.list?.slice(0, 6)" :key="item.id">
					<CaseItem
						:imageURL="item.thumbnail?.thumbnail"
						:title="item.title"
						:desc="item.abstract"
						:url="item.detail_link"
						:preferredAspectRatio="preferredAspectRatio"
					></CaseItem>
				</template>
			</div>
		</div>
	</CardContainer>
</template>

<script>
import CardContainer from "@/components/cards/CardContainer.vue";
import CaseItem from "@/components/cards/subcards/CaseGridItem.vue";

export default {
	props: {
		list: Object,
		title: { type: String, default: "案例" },
		subtitle: { type: String },
	},
	mounted() {
		this.updatePreferredAspectRatio(this.list);
	},
	name: "CaseCard",
	components: {
		CardContainer,
		CaseItem,
	},
	watch: {
		list: {
		handler(newValue) {
			// update aspect ratio with new value.
			this.updatePreferredAspectRatio(newValue);
		},
		deep: false
		}
	},
	data() {
		return {
			// title: "案例",
			preferredAspectRatio: 1.7777,
		};
	},
	methods: {
		updatePreferredAspectRatio: function(list) {
			// 平均值
			let aspectRatioSum = 0;
			let aspectRaioCount = 0;
			list.forEach(function(x) {
				let w = x.thumbnail?.width;
				let h = x.thumbnail?.height;
				if (h > 0) {
					let aspectRatio = w / h;
					// 排除一些明显特别扁和特别长条的比例
					if (aspectRatio < 3 && aspectRatio > 0.5) {
						aspectRatioSum += aspectRatio;
						aspectRaioCount++;
					}
				}
			});
			if (aspectRaioCount > 0) {
				let aspectRatio = aspectRatioSum / aspectRaioCount;
				this.preferredAspectRatio = aspectRatio;
			} else {
				this.preferredAspectRatio = 1.77777;
			}

			// console.log(this.preferredAspectRatio);
		},
	},
};
</script>

<style scoped></style>
