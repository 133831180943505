<template>
	<div class="list rounded">
		<div
			class="grid gap-1 row-gap-1 grid-cols-3 md:grid-cols-4 lg:grid-cols-6   md:row-gap-3 md:gap-3"
		>
			<template v-for="image in items" :key="image.imageURL">
				<a :href="image.url ? image.url : image.imageURL" :aria-label="image.title" target="_blank" class="group relative">
					<div
						class="aspect-w-16 aspect-h-12 group relative overflow-hidden transition duration-200 transform rounded-sm shadow-xs hover:shadow-lg"
					>
						<img
							class="object-cover w-full"
										:src="image.imageURL" 
							:alt="image.title"
							:title="image.title"
						/>
					</div>
				<div v-if="image.title" class="absolute inset-x-0 bottom-0 px-2 py-2 bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 duration-200">
                  <p class="text-xs font-medium tracking-wide text-white text-center">
                    {{image.title}}
                  </p>
                </div>
				</a>
			</template>
		</div>
	</div>
</template>

<script>

/* items:
    - imageURL
    - title
    - desc
*/
export default {
	props: {
		items: Object,
	},
	name: "GalleryContentGrid",
	components: {},
	data() {
		return {};
	},
};
</script>

<style scoped>
img[lazy=loading],img[lazy=loaded],img[lazy=error] {
	background-color: rgba(250,250,250,20);
}
</style>
