<template>
	<div class="top-info-container">
		<div class="logo-container logo-image-view"  :style="logoImageMaskStyles"> 
			<img class="company-logo-image" :src="logoImageURL" />
		</div>
		<div class="top-info-separator"></div>
		<!-- <a class="domain-container url-bar-image-view" :style="urlBarImageMaskStyles" 
		href="https://www.22.cn/CoLtd/?s=.co.ltd" target="_blank"
		> -->
		<a class="domain-container url-bar-image-view" :style="urlBarImageMaskStyles">
			<img class="url-bar-image" :src="urlBarImageURL" />
		</a>
	</div>
</template>

<script>
import { createLogoImage } from "@/utils/logo_renderer.js";
import { createURLBarImage } from "@/utils/urlbar_renderer.js";

const urlBarImageURL = require("@/assets/images/url-bar-120.png");
const dgLogoImageWithSeparatorURL = require("@/assets/images/digital-gate-with-separator-mask-with-margin.png");
const dgLogoImageURL = require("@/assets/images/digital-gate-mask.png");

export default {
	props: {
		logoURL: String,
		domain: String,
		tintColor: String,
	},
	name: "HeaderLogo",
	data() {
		return {
			logoImageURL: "",
			urlBarImageURL: "",
		};
	},
	components: {},
	computed: {
		logoImageMaskStyles: function() {
			if (this.logoImageURL) {
			return {
				"mask-image": "url(" + this.logoImageURL,
				"-webkit-mask-image": "url(" + this.logoImageURL,
			};
			}
			else {
				return undefined;
			}
		},
		urlBarImageMaskStyles: function() {
			if (this.urlBarImageURL) {
			return {
				"mask-image": "url(" + this.urlBarImageURL,
				"-webkit-mask-image": "url(" + this.urlBarImageURL,
			};
			}
			else {
				return undefined;
			}
		}
	},
	watch: {
		logoURL: {
			immediate: true,
			handler(newVal) {
				// console.log(newVal);
				if (newVal) {
					let dgURL = dgLogoImageURL
					if (newVal?.length > 0) {
						dgURL = dgLogoImageWithSeparatorURL;
					}
					createLogoImage([newVal, dgURL], true).then(
						(imageURL) => {
							this.logoImageURL = imageURL;
						}
					);
				} else {
					this.logoImageURL = "/";
					createLogoImage([dgLogoImageURL], true).then(
						(imageURL) => {
							this.logoImageURL = imageURL;
						}
					);
				}
			},
		},
		// logoImageURL: {
		// 				immediate: true,
		// 				handler(newVal) {
		// 	console.log(newVal);
		// 				}
		// },
		domain: {
			immediate: true,
			handler(newVal) {
				if (newVal) {
					// console.log(urlBarImageURL);
					createURLBarImage(newVal, urlBarImageURL, "white").then(
						(imageURL) => {
							this.urlBarImageURL = imageURL;
						}
					);
				} else {
					this.urlBarImageURL = "/";
				}
			},
		},
	},
};
</script>

<style scoped>
.top-info-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: var(--element-padding-normal);
	opacity: 1;
}

.logo-container,
.domain-container {
	height: 28px;
}
.top-info-separator {
	height: 100%;

	/* always takes place */
	width: 12%;
	flex-shrink: 0;
}

.logo-image-view[style], .url-bar-image-view[style] {
	mask-size: contain;
	-webkit-mask-size: contain;
	mask-repeat: no-repeat;
	-webkit-mask-repeat: no-repeat;
	/* background-color: red; */
	/* background: linear-gradient(
    90deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 10%,
    rgba(208, 222, 33, 1) 20%,
    rgba(79, 220, 74, 1) 30%,
    rgba(63, 218, 216, 1) 40%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 60%,
    rgba(95, 21, 242, 1) 70%,
    rgba(186, 12, 248, 1) 80%,
    rgba(251, 7, 217, 1) 90%,
    rgba(255, 0, 0, 1) 100%
);  */
	background-color:var(--theme-header-text-color);
}

.logo-image-view {
	mask-position: top left;
}

.url-bar-image-view {
	mask-position: top right;
}

.company-logo-image, .url-bar-image {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

.company-logo-image {
	object-position: top left;
}

img.company-logo-image[src='/'], img.url-bar-image[src='/'] {
	display: none;
}

.url-bar-image {
	object-position: top right;
}

.company-logo-image,
.url-bar-image {
	display: none;
}

.company-logo-image[src],
.url-bar-image[src] {
	display: block;
}

.logo-image-view[style] > img.company-logo-image {
	opacity: 0;
}

.url-bar-image-view[style] > img.url-bar-image {
	opacity: 0;
}


</style>
