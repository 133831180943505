<template>
	<a
		:href="url" target="_blank" 
		class="item group overflow-hidden transition-shadow rounded-sm"
	>
		<div class="item-container flex flex-row gap-3">
			<div class="info flex-1 flex flex-col justify-between">
				<div>
					<h4 v-if="desc.length > 0" class="title line-clamp-1">
						{{ title }}
					</h4>
					<h4 v-else class="title line-clamp-3">
						{{ title }}
					</h4>

					<p v-if="desc.length > 0" class="desc line-clamp-2 mt-1">
						{{ desc }}
					</p>
				</div>
				<p class="date mb-1">
					{{ date }}
				</p>
			</div>
				<img v-if="imageURL" :src="imageURL" class= "image object-contain object-center  flex-0 rounded-sm" :alt="title" />
		</div>
	</a>
</template>

<script>
export default {
	props: {
		desc: String,
		title: String,
		imageURL: String,
		url: String,
		date: String,
	},
	name: "NewsListItem",
	components: {},
	data() {
		return {};
	},
	computed: {},
};
</script>

<style scoped>
.item {
	display: block;
	height: 88px;
}

.item-container {
	height: 100%;
}

.title {
	font-size: 14px;
	font-weight: 500;
	opacity: 0.9;
}
.desc {
	font-size: 12px;
	opacity: 0.7;
}
.date {
	font-size: 12px;
	opacity: 0.5;
	font-weight: 400;
}
.image {
    display: block;
	width: 30%;
	height: auto;
}

img[lazy=loading],img[lazy=error] {
	background-color: rgba(250,250,250,20);
}
img[lazy=loaded] {
	background: rgba(255,255,255,0);
}

/* md style */
@media (min-width: 768px) {
	.item {
		height: 108px;
	}

	.title {
		font-size: 15px;
		font-weight: 600;
	}
	.desc {
		font-size: 14px;
	}
	.date {
		font-size: 13px;
	}
}
</style>
