<template>
  <div class="header">
        <HeaderLogo 
        :logoURL="info?.headerInfo?.dgLogoURL"
        :domain="info?.headerInfo?.dgDomain"
      ></HeaderLogo>
      <div class="content-container">
        <HeaderInfo v-if="shouldShowPlaceholderTitle"
        :title="info?.preferredDGDisplayName"
        :slogan="info?.slogan"
      ></HeaderInfo>
      <HeaderInfo v-else
        :title="title"
        :subtitle="subtitle"
        :slogan="info?.slogan"
      ></HeaderInfo>

      </div>
  </div>
</template>

<script>

import HeaderInfo from '@/components/header/HeaderInfo.vue'
import HeaderLogo from '@/components/header/HeaderLogo.vue'

export default {
  props: {
    info : Object,
  },
  name: 'Header',
  components: {
      HeaderLogo,
      HeaderInfo
  },
  computed: {
    shouldShowPlaceholderTitle() {
      const fullname = this.info?.companyFullName;
      const shortname = this.info?.companyShortName;
      if (fullname || shortname) {
          return false;
      }
      return true;
    },
    title() {
      if (this.info?.companyShortName) {
        return this.info?.companyShortName;
      }
      return this.info?.companyFullName;
    },
    subtitle() {
      // 如果没有shortname，那fullname已经在title中返回了
      if (this.info?.companyShortName) {
        return this.info?.companyFullName;
      }
      return "";
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped>
.header {
    width: 100%;
}

.header {
    border-radius: 0% 0% 120px 0%;
}

.header {
    background: var(--theme-header-background);
    background-size: cover;
    background-repeat: no-repeat;
}

.header {
  padding-bottom: 108px;
}

</style>