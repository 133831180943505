<template>
<div class="content-container flex flex-col items-center">

<div v-if="logoURL" class="logo">
    <img :src="logoURL"/>
</div>
<div class="name">{{name}}</div>
<div class="domain"><CopyButton :content="domain">{{domain}}</CopyButton></div>

</div>
</template>

<script>
import CopyButton from "@/components/cards/functional/CopyButton.vue";

export default {
    components : {
        CopyButton
    },
  name: 'Copyright',
  props: {
      logoURL: String,
      name: String,
      domain: String,
      slogan: String
  }
}
</script>

<style scoped>
.content-container {
    text-align: center;
    margin: 12px auto;
}

.logo {
    width: 64px;
    height: 64px;
    background-color: white;
    border-radius: 32px;
    margin-bottom: 8px;
    padding: 8px;
    overflow: hidden;
}
.logo > img {
    max-width: 100%;  
    height: 100%;
    object-fit: contain;
}

.name {
    color:#555555;
    font-size: 18px;
    font-weight: 600;
}

.domain {
    color: var(--theme-text-color);
    font-weight: 500;
    font-size: 15px;
    margin-top: 3px;
}

</style>
