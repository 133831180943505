<template>
	<CardContainer :title="title" :subtitle="subtitle" :showsAction="false">
		<ImageTextTBContent
			:imagesrc="imageURL"
			:title="text"
			:rawContent="rawContent"
		></ImageTextTBContent>
	</CardContainer>
</template>

<script>
import CardContainer from "@/components/cards/CardContainer.vue";
import ImageTextTBContent from "@/components/cards/subcards/ImageTextTBContent.vue";

export default {
	props: {
		title: { type: String, default: "品牌故事"},
		subtitle: { type: String},
		imageURL: String,
		text: String,
		rawContent: String,
	},
	name: "StoryCard",
	components: {
		CardContainer,
		ImageTextTBContent,
	},
	data() {
		return {
			// title: "品牌故事",
			//   imgSrc : "https://uploadfile.xcx.gw66.vip/uploadfile/image/0/16/222/2021-01/16117266433108.jpg",
			//   title : "20年前，我们做个生意只是选个址，开个店，或参个展；10年前，我们做个生意还需要建个网站，开个网店，投个搜索广告；5年之前，我们做个生意又需要弄个微博、微信来吸粉、拓客、引流；而如今，我们做个生意不仅需要做网站、开网店，还有做各种小程序（如：微信、百度、支付宝等）、微信号、头条号、百家号、企鹅号等等各种各类自媒体，除此之外，抖音、快手、微视各种短视频平台各种秀也要参与。 "
		};
	},
};
</script>

<style scoped></style>
