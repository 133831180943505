<template>
	<a :href="url" target="_blank"  class="item group overflow-hidden transition-shadow rounded-sm">
		<div class="image w-full" :class="itemClass">
			<img
			:src="imageURL" 
			class="object-cover"
			:alt="title"
			/>
		</div>
<div class="info">
		<div class="mt-2">
			<h4 class="title line-clamp-1">
				{{title}}
			</h4>
			<p class="desc line-clamp-2 md:line-clamp-3 mb-1">
{{desc}}
			</p>
		</div>
        </div>
	</a>
</template>

<script>
export default {
	props: {
		desc: String,
		title: String,
		imageURL: String,
		price: String,
        url: String,
		preferredAspectRatio: {
			type: Number,
			default: 1.77777
		}
	},
	name: "ProductGridItem",
	components: {},
	data() {
		return {
			// itemClass : "aspect-w-16 aspect-h-9",
		};
	},
	computed: {
		aspectClass () {

			const ratio = this.preferredAspectRatio;
			if (ratio > 1 && ratio <= 5) {
				// w > h
				return "aspect-w-16 aspect-h-" + parseInt(16 / ratio);
			}
			else if (this.preferredAspectRatio > 0.2) {
				// w < h
				return "aspect-h-16 aspect-w-" + parseInt(16 * ratio);
			}
			else {
				// invalid
				return "aspect-w-16 aspect-h-9";
			}
		},
		itemClass() {
			return this.aspectClass
		},
	},
};
</script>

<style scoped>
.item {
    display: block;

}

.title {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.9;
}
.desc {
    font-size: 12px;
opacity: 0.7;
}
.price {
    font-size: 13px;
    color: var(--theme-text-color);
    font-weight: 600;;
}
.image {
    background-color: #eee;
}

img[lazy=loading],img[lazy=loaded],img[lazy=error] {
	/* background-color: rebeccapurple; */
	background-color: rgba(250,250,250,20);
}

@media (min-width: 768px) {

	.title {
		font-size: 15px;
	}
	.desc {
		font-size: 13px;
	}
}


</style>
