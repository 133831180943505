<template>
	<div class="loading" v-if="!dataReady">{{ loadingText }}</div>
	<div class="main-page-container" v-if="dataReady">
		<MainPageContainer :displayData="displayData"></MainPageContainer>
		<QRCodeDialog></QRCodeDialog>
		<CardCodeDialog></CardCodeDialog>
		<MapActionDialog></MapActionDialog>
	</div>
</template>

<script>
// @ is an alias to /src
import MainPageContainer from "@/components/container/MainPageContainer.vue";
import QRCodeDialog from "@/components/modals/QRCodeDialog.vue";
import CardCodeDialog from "@/components/modals/CardCodeDialog.vue";
import MapActionDialog from "@/components/modals/MapActionDialog.vue";
import { getDisplayDomain } from "@/utils/utilities.js";

import axios from "axios";

// mock
// import response from "@/mock/response/digitalgate_info.json";
// import { mockResponseData } from "@/mock/response/mockresponse.js";
import { prepareDisplayData } from "@/utils/display_data_setup.js";
import { setupThemeStyles } from "@/utils/theme_setup.js";
import textVersion from "textversionjs";

export default {
	name: "Main",
	data() {
		return {
			displayData: {},
			configData: {},
			dataReady: false,
			requestErrors: [],
		};
	},
	computed: {
		loadingText() {
			// let host = window.location.host;
			let host = getDisplayDomain();
			if (host?.length > 0) {
				return " 正在进入数字门户…\n" + host;
			} else {
				return " 正在进入数字门户…";
			}
		},
	},
	components: {
		MainPageContainer,
		QRCodeDialog,
		CardCodeDialog,
		MapActionDialog,
	},
	methods: {
		// metaInfo() {
		// 	return {
		// 		title: "Epiloge - Build your network in your field of interest",
		// 		meta: [
		// 			{
		// 				name: "description",
		// 				content:
		// 					"Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.",
		// 			},
		// 			{
		// 				property: "og:title",
		// 				content: "Epiloge - Build your network in your field of interest",
		// 			},
		// 			{ property: "og:site_name", content: "Epiloge" },
		// 			{ property: "og:type", content: "website" },
		// 			{ name: "robots", content: "index,follow" },
		// 		],
		// 	};
		// },
		// prepareData() {
		// 	let value = mockResponseData();
		// 	value = prepareDisplayData(value);
		// 	this.displayData = value;
		// 	this.dataReady = true;
		// 	// console.log(this.displayData)
		// },
		setupData(infoData, configData) {
			setupThemeStyles(configData);
			let displayData = prepareDisplayData(infoData, configData);

			this.displayData = displayData;
			this.configData = configData;
			this.dataReady = true;

			if (process.env.NODE_ENV == "development") {
				// console.log(process.env.NODE_ENV);
			} else {
				// 只有当生产环境才发送
				const websiteId = infoData.info.website_id;
				// console.log(websiteId);
				const scriptLabel = document.createElement("script");
				scriptLabel.innerText = `_ga.url = 'https://wei.ltd.com/analyst';_ga.params['wi'] = '3';_ga.params['ui'] ='0';_ga.init(${websiteId});`;
				document.querySelector("body").appendChild(scriptLabel);
			}
			// initAnalyst(infoData.info.website_id);

			this.updateDocumentMeta();
		},
		updateDocumentMeta() {
			let docTitle = this.displayData?.info?.preferredDGDisplayName;
			if (docTitle && docTitle.length > 0) {
				document.title = docTitle;
			} else {
				document.title = "数字门户";
			}

			let desc = "";
			try {
				desc = textVersion(this.displayData?.info?.intro?.rawContent);
				desc = desc.replace(/\s+/g, "X");
			} catch (error) {
				// error
			}
			if (!desc || desc.length == 0) {
				desc = "";
			}

			
			{
				let existed = false;
				let meta = document.getElementsByTagName("meta");
				for (let i = 0; i < meta.length; i++) {
					if (meta[i].name.toLowerCase() == "description") {
						existed = true;
						meta[i].content = desc;
					}
				}
				if (!existed) {
					let meta = document.createElement("meta");
					meta.setAttribute("name", "description");
					meta.content = desc;
					document.getElementsByTagName("head")[0].appendChild(meta);
				}
			}
			let metaProperties = {
				"og:title": docTitle,
				"og:image": this.displayData?.info?.preferredShareImageURL,
			};
			let dgDomainURL = this.displayData?.info?.headerInfo?.dgDomain;
			if (dgDomainURL && dgDomainURL.length > 0) {
				metaProperties["og:url"] = "https://" + dgDomainURL;
			}
			for (let property in metaProperties) {
				let content = metaProperties[property];
				if (content) {
					let existed = false;
					let meta = document.getElementsByTagName("meta");
					for (let i = 0; i < meta.length; i++) {
						if (meta[i].getAttribute("property") == property) {
							existed = true;
							meta[i].content = desc;
						}
					}
					if (!existed) {
						let meta = document.createElement("meta");
						meta.setAttribute("property", property);
						meta.content = desc;
						document.getElementsByTagName("head")[0].appendChild(meta);
					}
				}
			}
			// favicon
			{
				let avatarURL = this.displayData?.info?.avatarLogoURL;
				if (avatarURL) {
					let existed = false;
					let meta = document.getElementsByTagName("link");
					for (let i = 0; i < meta.length; i++) {
						if (meta[i].getAttribute("rel") == "icon") {
							existed = true;
							meta[i].href = avatarURL;
						}
					}
					if (!existed) {
						let meta = document.createElement("link");
						meta.setAttribute("rel", "icon");
						meta.href = avatarURL;
						document.getElementsByTagName("head")[0].appendChild(meta);
					}
				}
			}
			// <link rel="shortcut icon" href="https://uploadfile.xcx.gw66.vip/uploadfile/image/0/16/222/2020-12/16085379926518.jpg">
		},
	},
	mounted() {
		// console.log("mounted");
		// console.log(mockResponseData())

		// this.prepareData();

		let info = "/api/digitalgate/info";
		let config = "/api/digitalgate/config";
		let that = this;

		axios
			.all([axios.get(info), axios.get(config)])
			.then(
				axios.spread((...responses) => {
					let infoResponse = responses[0];
					let configResponse = responses[1];

					let infoResponseData = infoResponse.data?.data;
					let configResponseData = configResponse.data?.data;

					// console.log(infoResponseData)
					// test/
					// const testConfig = {
					// 	header_background_css:
					// 		"url('https://uploadfile.xcx.gw66.vip/uploadfile/image/0/16/222/2021-09/16328243005888.png')",
					// 	header_text_color: "white",
					// 	theme_text_color: "#8b33ff",
					// 	domain: "LTD.Co.Ltd",
					// 	logo_url:
					// 		"https://uploadfile.xcx.gw66.vip/uploadfile/image/0/16/222/2021-09/16328254374783.png",
					// };
					// configResponseData = testConfig;
					that.setupData(infoResponseData, configResponseData);
				})
			)
			.catch((errors) => {
				that.requestErrors = errors;
			});
	},
};
</script>
<style scoped>
.loading {
	color: #888;
	font-size: 14px;
	position: fixed; /* or absolute */
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	white-space: pre-wrap;
}
</style>
