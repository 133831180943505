import { createStore } from 'vuex'

export default createStore({
  state: {
      websiteId: 0,
      // 监听这个session来触发更新
      statisticDataSession: 0,
  },
  mutations: {
    updateWebsiteId (state, websiteId) {
      state.websiteId = websiteId;
    },
    // 触发更新 this.$store.commit('needsUpdateStatisticData')
    needsUpdateStatisticData(state) {
      state.statisticDataSession += 1; 
    }
  },
  actions: {
  },
  modules: {
  }
})
