<template>
	<div class="container">
		<div class="banner large aspect-w-16 aspect-h-4"></div>
		<div class="banner narrow aspect-w-16 aspect-h-9"></div>
		<div class="guide-container">
			<div class="domain-guide">
				<div class="domain-guide-container">
					<div v-if="domain.length > 0" class="domain">{{ displayDomain }}</div>
					<div class="guide">
						<p>该数字门户尚未配置内容</p>
						<p></p>
						<p>
							立即访问
							<a
								class="admin-link"
								href="https://i.22.cn/coltd"
								target="_blank"
							>
								管理后台
							</a>
							进行配置
						</p>
						<p></p>
					</div>
				</div>
			</div>
			<div class="intro">
				<ul>
					<li>
						“.Co.Ltd有限公司数字门户”，简称“.Co.Ltd数字门户”，实现公司英文名称就是公司数字化在线门户。
					</li>

					<li>
						“.Co.Ltd数字门户”高效连通公司网站、小程序、社交媒体等企业各类媒体号，让每一个公司都拥有一个数字化入口。“.Co.Ltd数字门户”的命名具有全球唯一性，先到先得，其命名与数字门户应用具有一体化，可以整体PUSH和转让。除了“.Co.Ltd数字门户”命名外，内容可更换更新，拥有独立管理后台。
					</li>

					<li>用一个等式表示即：</li>
				</ul>
				<p class="intro-text">
					.Co.Ltd数字门户 = 一个.Co.Ltd域名 + 一个Ltd营销枢纽门户官网
				</p>
			</div>
		</div>
	</div>
	<Powerby name="营销枢纽" class="powerby"></Powerby>
</template>
<script>
import Powerby from "@/components/others/Powerby.vue";

export default {
	props: {
		domain: {
			type: String,
			default: "",
		},
	},
	name: "ConfigGuide",
	components: {
		Powerby,
	},
	data() {
		return {
			// domain: "Companyname.Co.Ltd",
		};
	},
	computed: {
		displayDomain() {
			return this.domain.replace(/^(.+)\.co\.ltd$/i, "$1.Co.Ltd");
		},
	}
};
</script>

<style scoped>
/* https://www.22.cn/CoLtd/content/images/banner.jpg */
.container {
	background: white;
	max-width: 960px;

	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.09);
	border-radius: 4px;
	margin: 48px auto;
	margin-bottom: 12px;
	overflow: hidden;
}
.powerby {
	margin-bottom: 48px;
}

.banner {
	background-image: url("https://www.22.cn/CoLtd/content/images/banner.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 100px;
}

.guide-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.domain-guide,
.intro {
	width: 50%;
}

.guide {
	font-size: 13px;
	color: #555;
}

.domain {
	font-size: 24px;
	font-weight: bold;
	font-family: sans-serif;
	margin-bottom: 12px;
	color: #333;
}

.admin-link {
	text-decoration: underline;
	font-weight: bold;
	color: #ff7b4f;
}

.intro {
	font-size: 13px;
	line-height: 20px;
	padding: 36px 32px 54px 32px;
}

.domain-guide {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.domain-guide-container > * {
	width: auto;
}

.intro li {
	position: relative;
	margin: 12px 0;
	list-style: none;
}
.intro li:before {
	content: "";
	width: 8px;
	height: 8px;
	border-radius: 10px;
	position: absolute;
	top: 6px;
	left: -16px;
	background: #fe8432;
}

.intro-text {
	font-size: 18px;
	color: #555;
	line-height: 28px;
}

.banner.narrow {
    display: none;
}
.banner.large {
    display: block;
}

/* 窄屏 */
@media only screen and (max-width: 568px) {
	.container {
		width: 100%;
		overflow: hidden;
		margin: 0;
		margin-bottom: 12px;
	}

	.domain-guide,
	.intro {
		width: 100%;
	}
	.domain-guide {
		margin-top: 72px;
		margin-bottom: 72px;
	}
	.intro {
		padding-top: 0;
	}
	.guide-container {
		display: block;
	}

	.powerby {
		margin-bottom: 18px;
	}
    .banner.narrow {
    display: block;
}
.banner.large {
    display: none;
}
}

</style>
