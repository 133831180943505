import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueClipboard from 'vue-clipboard2'
import VueLazyLoad from 'vue3-lazyload'
import './assets/styles/global.css'
import './assets/styles/themes.css'
import './assets/styles/tailwind.css'
// import './assets/styles/themes/ltdgay.css'
// import './assets/styles/themes/gold.css'

createApp(App).use(store).use(router).use(VueClipboard).use(VueLazyLoad).mount('#app')


