<template>
	<a
		:href="url"
		target="_blank"
		class="item group overflow-hidden transition-shadow rounded-sm"
	>
		<div class="image w-full" :class="itemClass">
			<img :src="imageURL" class="object-cover" :alt="title" v-if="imageURL"/>
		</div>
		<div class="info">
			<div class="mt-2">
				<template v-if="this.abstract.length > 0">
					<h4 class="title line-clamp-2">
						{{ title }}
					</h4>
				</template>
				<template v-else>
					<h4 class="title line-clamp-3">
						{{ title }}
					</h4>
				</template>
				<p class="desc line-clamp-2 md:line-clamp-3 mb-1 mt-1">
					{{ this.abstract }}
				</p>
			</div>
		</div>
	</a>
</template>

<script>
export default {
	props: {
		desc: String,
		title: String,
		imageURL: String,
		url: String,
		preferredAspectRatio: {
			type: Number,
			default: 1.77777
		}
	},
	name: "CaseGridItem",
	components: {},
	data() {
		return {};
	},
	computed: {
		abstract: function() {
			return this.desc ? this.desc : "";
		},
		aspectClass () {
			const ratio = this.preferredAspectRatio;
			// console.log(ratio);
			if (ratio > 1 && ratio <= 5) {
				// w > h
				return "aspect-w-16 aspect-h-" + parseInt(16 / ratio);
			}
			else if (this.preferredAspectRatio > 0.2) {
				// w < h
				return "aspect-h-16 aspect-w-" + parseInt(16 * ratio);
			}
			else {
				// invalid
				return "aspect-w-16 aspect-h-9";
			}
		},
		itemClass() {
			return this.aspectClass
		},
	},
};
</script>

<style scoped>
.item {
	display: block;
}

.title {
	font-size: 14px;
	font-weight: 500;
	opacity: 0.9;
}
.desc {
	font-size: 12px;
	opacity: 0.7;
}
.price {
	font-size: 13px;
	color: var(--theme-text-color);
	font-weight: 600;
}

.image {
	background-color: rgba(255,255,255,0);
}


img[lazy=loading],img[lazy=loaded],img[lazy=error] {
	background-color: rgba(250,250,250,20);
}

img[lazy=loaded] {
	background-color: rgba(255,255,255,0);
}

.image img {
	object-fit: contain;
}

/* md style */
@media (min-width: 768px) {

	.title {
		font-size: 17px;
		font-weight: 600;
	}
	.desc {
		font-size: 14px;
	}
}

</style>
