<template>
	<CardContainer :title="title" :subtitle="subtitle" :showsAction="false">
		<Gallery :items="list"></Gallery>
	</CardContainer>
</template>

<script>
import CardContainer from "@/components/cards/CardContainer.vue";
// import Gallery from "@/components/cards/subcards/GalleryContentHorizontal.vue";
import Gallery from "@/components/cards/subcards/ImageGalleryContentGrid.vue";

export default {
	props: {
		list: Object,
		title: { type: String, default: "图集"},
		subtitle: { type: String},
	},
	name: "AlbumCard",
	components: {
		CardContainer,
		Gallery,
	},
	computed: {},
	data() {
		return {
			// title: "图集",
		};
	},
};
</script>

<style scoped></style>
