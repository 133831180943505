<template>
	<div class="item-container" @click="handleClick">
		<div class="icon">
			<img v-if="iconURL && iconURL.length > 0" :src="iconURL" />
		</div>
		<div class="info-container">
			<div class="name">{{ name }}</div>
			<button v-if="showsQRAction" class="qr"></button>
		</div>
	</div>
</template>

<script>
import { $vfm } from "vue-final-modal";

export default {
	props: {
		platform: String,
		type: String,
		name: String,
		QRCodeURL: String,
		QRCodeContent: String,
	},
	name: "WebsiteListItem",
	components: {},
	data() {
		return {
			icons: {
				socialmedia: {
					baidu: require("@/assets/images/SocialMedias/social_media_baidu.png"),
					wechat: require("@/assets/images/SocialMedias/social_media_wechat.png"),
					douyin: require("@/assets/images/SocialMedias/social_media_douyin.png"),
					dayu: require("@/assets/images/SocialMedias/social_media_dayu.png"),
					toutiao: require("@/assets/images/SocialMedias/social_media_toutiao.png"),
					weibo: require("@/assets/images/SocialMedias/social_media_weibo.png"),
					kuaishou: require("@/assets/images/SocialMedias/social_media_kuaishou.png"),
					tencent: require("@/assets/images/SocialMedias/social_media_tencent.png"),
					zhihu: require("@/assets/images/SocialMedias/social_media_zhihu.png"),
				},
				miniprogram: {
					baidu: require("@/assets/images/SocialMedias/miniprogram_baidu.png"),
					wechat: require("@/assets/images/SocialMedias/miniprogram_wechat.png"),
					douyin: require("@/assets/images/SocialMedias/miniprogram_douyin.png"),
				},
			},
		};
	},
	computed: {
		iconURL: function() {
			if (this.type) {
				let map = this.icons[this.type];
				let url = "";
				if (map && this.platform) {
					url = map[this.platform];
				}
				if (url && url.length > 0) {
					return url;
				}
			}
			return undefined;
		},
		showsQRAction: function() {
			return (
				(this.QRCodeURL && this.QRCodeURL.length > 0) ||
				(this.QRCodeContent && this.QRCodeContent.length > 0)
			);
		},
	},
	methods: {
		handleClick: function() {
			if (this.showsQRAction) {
				$vfm.show("qrmodal", {
					QRContent: this.QRCodeContent,
					QRImageURL: this.QRCodeURL,
					title: this.name,
				});
			}
		},
	},
};
</script>

<style scoped>
.item-container {
	display: flex;
	height: 44px;
	align-items: center;
}

.icon {
	height: 32px;
	width: 32px;

	flex-grow: 0;
	flex-shrink: 0;

	/* placeholder */
	background-color: #f0f0f0;
	border-radius: 16px;
}

.icon > img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.info-container {
	height: 36px;
	margin-left: 12px;
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	background-color: #f9f9f9;
	border-radius: 4px;
	align-items: center;
	justify-content: space-between;
}

.info-container .name {
	flex-grow: 1;
	flex-shrink: 1;
	margin-left: 12px;
	font-size: 15px;
	color: #333;
	font-weight: 500;

	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.info-container .qr {
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: 12px;
	height: 14px;
	width: 14px;
	mask-image: url("~@/assets/images/icon-qr.png");
	-webkit-mask-image: url("~@/assets/images/icon-qr.png");
	mask-size: contain;
	-webkit-mask-size: contain;
	background: #888;
}
</style>
