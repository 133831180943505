<template>
	<CardContainer :showsAction="false" v-if="imageURL">
        <div class="image" v-if="imageURL?.length > 0">
				<img :src="imageURL" />
		</div>
	</CardContainer>
</template>

<script>

import CardContainer from '@/components/cards/CardContainer.vue'

export default {
  props: {
    list: Object,
  },
  computed : {
      imageURL : function () {
          if (this.list && this.list.length > 0) {
            let item = this.list[0];
            return item.imageURL;
          }
          return undefined;
      },
  },
  name: 'BannersCard',
  components: {
    CardContainer,
  },
  data () {
    return {
      }
    }
}
</script>

<style scoped>
img[lazy=loading],img[lazy=loaded],img[lazy=error] {
	background-color: rgba(250,250,250,20);
}
.image {
    width: 100%;
}
.image img {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
}
</style>
