<template>
	<CardContainer :title="title" :subtitle="subtitle" :showsAction="false">
		<div class="content">
			<template v-for="item in this.websites?.slice(0,1)" :key="item.id">
			<WebsitePreviewItem 
				:name="item.name"
				:url="item.url"
				:previewImageURL="item.previewImageURL"
			></WebsitePreviewItem>
			</template>
			<SubContentContainer title="公众号" v-if="this.socialAccounts?.length > 0">
				<div class="website-items">
					<template v-for="item in this.socialAccounts" :key="item.id">
						<WebsiteListItem
							type="socialmedia"
							:platform="item.platform"
							:name="item.name"
							:QRCodeURL="item.qrURL"
						></WebsiteListItem>
					</template>
				</div>
			</SubContentContainer>
			<SubContentContainer title="小程序" v-if="this.miniprograms?.length > 0">
				<div class="website-items">
					<template v-for="item in this.miniprograms" :key="item.id">
						<WebsiteListItem
							type="miniprogram"
							:platform="item.miniprogramType"
							:name="item.name"
							:QRCodeURL="item.qrURL"
						></WebsiteListItem>
					</template>
				</div>
			</SubContentContainer>

		</div>
	</CardContainer>
</template>

<script>
import CardContainer from "@/components/cards/CardContainer.vue";
import SubContentContainer from "@/components/cards/SubContentContainer.vue";
import WebsiteListItem from "@/components/cards/subcards/WebsiteListItem.vue";
import WebsitePreviewItem from "@/components/cards/subcards/WebsitePreviewItem.vue";

export default {
	props: {
		title: { type: String, default: "官方网站"},
		subtitle: { type: String},
		websites: Object,
		miniprograms: Object,
		socialAccounts: Object
	},
	name: "WebsitesCard",
	components: {
		CardContainer,
		WebsiteListItem,
		WebsitePreviewItem,
		SubContentContainer,
	},
	data() {
		return {
			// title: "官方网站",
		};
	},
};
</script>

<style scoped>
.content > * {
	margin: 20px 0;
}

.content > *:last-child {
	margin-bottom: 0;
}

.content > *:first-child {
	margin-top: 0;
}

.website-items > * {
	margin: 12px 0;
}

.website-items > *:last-child {
	margin-bottom: 0;
}
.website-items > *:first-child {
	margin-top: 0;
}
</style>
