<template>
	<CardContainer :title="title" :subtitle="subtitle" :showsAction="false">

		<div class="list">
					<div
			class="grid gap-4 gap-y-4 grid-cols-1 md:grid-cols-2 md:gap-10 md:gap-y-6 "
		>
            <template v-for="item in list.slice(0, 12)" :key="item.id">
			<MemberListItem
				:imageURL="item.entity_relate?.avatar?.thumbnail"
				:name="item.entity_relate?.realname"
				:duty="item.entity_relate?.duty"
				:intro="item.intro"
				:QRCodeURL="item.card_qrcode"
			></MemberListItem>
            </template>
		</div>
				</div>

	</CardContainer>
</template>

<script>
import CardContainer from "@/components/cards/CardContainer.vue";
import MemberListItem from "@/components/cards/subcards/MemberListItem.vue";

export default {
    props: {
        list: Object,
		title: { type: String, default: "成员"},
		subtitle: { type: String},
	},
	name: "MemberCard",
	components: {
		CardContainer,
		MemberListItem,
	},
	data() {
		return {
			// title: "成员",
		};
	},
};
</script>

<style scoped>
.list {
    padding-top: 8px;
}

.list > * {
    margin: 12px 0;
}

.list > *:last-child {
margin-bottom: 0;
}
.list > *:first-child {
    margin-top: 0;
}

</style>
