<template>
	<div class="item" @click="handleClick" :class="itemClass">
		<div class="icon" :class="type"></div>
		<div class="value">{{ value }}</div>
	</div>
</template>

<script>
import axios from "axios";

export default {
	components: {},
	data() {
		return {
			displayNumber: this.number,
			highlighted: false,
		};
	},
	name: "StatisticItem",
	props: {
		number: {
			type: Number,
		},
		type: {
			type: String,
		},
		showShort: {
			type: Boolean,
			default: false,
		},
		//   highlighted: {
		//       type: Boolean,
		//   }
	},
	mounted() {
		this.updateHighlightedState();

		//   this.displayNumber = this.number;
	},
	watch: {
		number: {
			immediate: true,
			handler(newVal) {
				this.displayNumber = newVal;
			},
		},
	},
	methods: {
		handleClick() {
			if (this.type == "likes") {
				this.handleLike();
			} else if (this.type == "messages") {
				this.goToAdmin();
			} else if (this.type == "views") {
				this.goToAdmin();
			} else if (this.type == "exposures") {
				this.goToAdmin();
			}
		},
		handleLike() {
			this.handleSubmitLike();
		},
		//   handleViews() {
		//     const url = "https://wei.ltd.com/user/";
		//     window.open(url, "_blank");
		//   },
		//   handleMessages () {
		//     const url = "https://wei.ltd.com/user/";
		//     window.open(url, "_blank");
		//   },
		// handleExposures () {

		//   },
		goToAdmin() {
			const url = "https://wei.ltd.com/user/";
			window.open(url, "_blank");
		},
		updateHighlightedState() {
			if (this.type == "likes") {
				this.updateLikedState();
			}
		},
		updateLikedState() {
			const api = "/api/digitalgate/action/isLike";
			axios({
				method: "get",
				url: api,
				params: {
					referer: window.location.href,
					// "referer": "https://ces.test.supenr.com",
				},
			})
				.then((res) => {
					// console.log(res);
					if (res.data) {
						this.highlighted = res.data.data;
					}
					// res;
				})
				.catch((error) => {
					error;
				});
		},
		updateLikeNumber() {
            // trigger
            this.$store.commit('needsUpdateStatisticData');

			// const api = "/api/digitalgate/data";
			// axios
			// 	.get(api)
			// 	.then((res) => {
			// 		const data = res.data?.data;
			// 		if (data?.like_count) {
			// 			this.displayNumber = parseInt(data.like_count);
			// 		}
			// 	})
			// 	.catch((error) => {
			// 		error;
			// 	});
		},
		handleSubmitLike() {
			const api = "/api/digitalgate/action/like";

			axios
				.post(api, {
					referer: window.location.href,
					// "referer": "https://ces.test.supenr.com",
					source: "1",
					relative: "点赞了数字门户: " + window.document.title,
					dt: "pc",
					type: 7,
				})
				.then((res) => {
					this.updateLikeNumber();
					// const updatedTotal = res.data?.data?.total;
					// if (updatedTotal) {
					//     this.displayNumber = parseInt(updatedTotal);
					// }
					// const data = res.data?.data;
					// console.log(res.data);
					this.updateLikedState();

					res;
				})
				.catch((error) => {
					error;
				});
		},
	},
	computed: {
		value() {
			// console.log(this.number);

			var number = 0;
			if (!this.displayNumber || this.displayNumber <= 0) {
				//
			} else {
				number = this.displayNumber;
			}
			number = parseInt(number);

			// console.log(number);
			if (this.showShort) {
				if (number <= 0) {
					// nothing.
					return "";
				} else if (number < 1000) {
					// 1 ~ 999
					return "" + number;
				} else if (number < 99000) {
					// 1k ~ 99k
					return "" + parseInt(number / 1000) + "k";
				} else if (number < 9990000) {
					// 10w ~ 999w
					return "" + parseInt(number / 10000) + "w";
				} else {
					return "999w+";
				}
			} else {
				if (number <= 0) {
					// nothing.
					return "";
				} else {
					return "" + number;
				}
			}
		},
		itemClass() {
			return this.highlighted ? "highlighted" : "";
		},
	},
};
</script>

<style scoped>
.item {
	display: flex;
	gap: 4px;
	align-items: center;
	justify-content: flex-start;
	/* max-width: 88px; */
}

.item {
	cursor: pointer;
}

.item.highlighted >>> .value {
	color: var(--theme-text-color);
}

.item.highlighted >>> .icon {
	background: var(--theme-text-color);
}

.value {
	color: #ada6a1;
	font-weight: 600;
	font-family: "SF Mono", "Menlo", "Helvetica", sans-serif;
	font-size: 14px;
}

.icon {
	/* test */
	background: #ada6a1;
	width: 20px;
	height: 20px;
	/* flex: 0 0; */
}

.icon >>> img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.icon {
	mask-size: contain;
	-webkit-mask-size: contain;
}

.icon.likes {
	mask-image: url("~@/assets/images/Statistic/statistic-likes.png");
	-webkit-mask-image: url("~@/assets/images/Statistic/statistic-likes.png");
}

.icon.messages {
	mask-image: url("~@/assets/images/Statistic/statistic-messages-2.png");
	-webkit-mask-image: url("~@/assets/images/Statistic/statistic-messages-2.png");
}
.icon.views {
	mask-image: url("~@/assets/images/Statistic/statistic-chart.png");
	-webkit-mask-image: url("~@/assets/images/Statistic/statistic-chart.png");
}

.icon.exposures {
	mask-image: url("~@/assets/images/Statistic/statistic-views.png");
	-webkit-mask-image: url("~@/assets/images/Statistic/statistic-views.png");
}

@media screen and (max-width: 554px) {
    .value {
            font-size: 12px;
    }
}

</style>
