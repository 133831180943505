<template>
	<CardContainer :title="title" :subtitle="subtitle" :showsAction="false">
		<Gallery :items="list?.slice(0,24)"></Gallery>
	</CardContainer>
</template>

<script>
import CardContainer from "@/components/cards/CardContainer.vue";
import Gallery from "@/components/cards/subcards/ImageGalleryContentGrid.vue";

export default {
	props: {
		list: Object,
		title: { type: String, default: "荣誉"},
		subtitle: { type: String},
	},
	name: "HonorsCard",
	components: {
		CardContainer,
		Gallery,
	},
	data() {
		return {
			// title: "荣誉",
		};
	},
};
</script>

<style scoped></style>
