import { createRouter, createWebHistory } from "vue-router";
// import Home from '../views/Home.vue'
// import Main from "../views/Main.vue";
import MultistepMain from "../views/MultistepMain.vue";
import MainLoadingStyle from "../views/MainLoadingStyle.vue";

const routes = [
	{
		path: "/home",
		name: "MainLoadingStyle",
		component: MainLoadingStyle,
	},
	{
		path: "/",
		name: "MultistepMain",
		component: MultistepMain,
	},
	// {
	//   path: '/home',
	//   name: 'Home',
	//   component: Home
	// },
	// {
	//   path: '/about',
	//   name: 'About',
	//   // route level code-splitting
	//   // this generates a separate chunk (about.[hash].js) for this route
	//   // which is lazy-loaded when the route is visited.
	//   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	// }
];

const router = createRouter({
	history: createWebHistory('/'),
	routes,
});

export default router;
