<template>
	<div ref="container">
		<VueQrcode
			class="qr-canvas-generator"
			colorDark="black"
			:text="qrcontent"
			:callback="URLUpdated"
			:size="600"
            :correctLevel="correctLevel"
		></VueQrcode>
	</div>
</template>
<script>
// https://github.com/Binaryify/vue-qr
import VueQrcode from "vue-qr/src/packages/vue-qr.vue";

export default {
    props : {
        text: String,
        logoSrc: String
    },
	data() {
		return {
			generatedQRImageURL: "",
            correctLevel: 3
		};
	},
    computed: {
        qrcontent: function() {
            let content = this.text?this.text:"";
            return content;
        }
    },
	components: {
		VueQrcode,
	},
	methods: {
        URLUpdated: function(url) {
            this.generatedQRImageURL = url;
        },
    },
	mounted() {},
};
</script>

<style scoped>

</style>
