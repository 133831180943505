<template>
	<div>
		<vue-final-modal
			v-model="show"
			name="cardcodemodal"
			classes="flex justify-center items-center"
		>
			<template v-slot="{ params }">
				<div class="flex flex-col gap-y-4 items-center">
					<div
						class="modal-container flex flex-col gap-y-4 items-center px-8 py-6 border bg-white"
					>
						<div class="title-container mt-2">
							<div class="title">{{ params.title }}</div>
							<div class="duty mt-0" v-if="params.duty?.length > 0">
								{{ params.duty }}
							</div>
						</div>
						<div ref="qrPrint" class="qr-code-image">
							<template v-if="params.QRImageURL">
								<img :src="params.QRImageURL" />
							</template>
							<template v-else>
								<QRContent
									ref="qrcontent"
									:text="params.QRContent"
									:logoSrc="params.avatarURL"
								></QRContent>
							</template>
						</div>
						<div class="indicator">
							<p>扫一扫或者长按识别</p>
							<p>立即访问成员名片</p>
						</div>
					</div>
					<button class="md:mt-8 mt-4" @click="show = false">
						<div class="close"></div>
					</button>
				</div>
			</template>
		</vue-final-modal>
	</div>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
import QRContent from "@/components/cards/functional/QRContentView.vue";
// import FileSaver from "file-saver";

// https://vue-final-modal.org/zh-Hant/api
export default {
	components: {
		VueFinalModal,
		QRContent,
	},
	data: () => ({
		show: false,
	}),
	watch: {
		show: function(val) {
			if (!val) {
				// make some cleanups?
			}
		},
	},
	methods: {},
};
</script>
<style scoped>
.modal-container {
	border-radius: 24px;
	/* max-width: 90%; */
}

.close {
	width: 40px;
	height: 40px;
	background: url("~@/assets/images/icon-modal-close.png");
	background-size: contain;
}
.qr-code-image {
	width: 200px;
	height: 200px;
}
.qr-code-image img {
	object-fit: contain;
}

.title-container * {
	text-align: center;
}

.title {
	font-size: 15px;
	font-weight: 500;
}

.duty {
	font-size: 14px;
	color: #888;
}

.indicator {
	font-size: 14px;
	color: #888;
	text-align: center;
}
.save-image-button {
	background-color: var(--theme-text-color);
	color: white;
	font-size: 15px;
	font-weight: 500;
	height: 40px;
	border-radius: 20px;
}
</style>
