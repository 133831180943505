function TencentMapToBaiduMap(lng, lat) {
	//经度，纬度
	// console.log(lng);
	// console.log(lat);

	let x_pi = (Math.PI * 3000.0) / 180.0; //Math.PI ~ 3.14159265358979324
	let x = lng;
	let y = lat;
	let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
	let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
	let lngs = z * Math.cos(theta) + 0.0065;
	let lats = z * Math.sin(theta) + 0.006;
	return {
		longitude: lngs,
		latitude: lats,
	};
}

export function OpenMapURL(longitude, latitude, address) {
	// default Use this.
	OpenBaiduMapURL(longitude, latitude, address);
}

function OpenBaiduMapURL(longitude, latitude, address) {
	let baiduLocation = TencentMapToBaiduMap(longitude, latitude);
	// console.log(baiduLocation);
	let url =
		"http://api.map.baidu.com/marker?location=" +
		baiduLocation.logitude +
		"," +
		baiduLocation.latitude +
		"&title=&content=" +
		address +
		"&output=html&src=" +
		window.location.href;
	// console.log(url);
	window.open(url, "_blank");
}

// Types

export function GetSupportedMapActions() {
	let platform = GetPlatform();
	if (platform.android) {
		if (platform.weChat) {
			return GetWebSupportedMapActions();
		} else {
			return GetAndroidSupportedMapActions();
		}
	} else if (platform.iOS) {
		if (platform.weChat) {
			return GetIOSWechatSupportedMapActions();
		} else {
			return GetIOSSupportedMapActions();
		}
	} else {
		return GetWebSupportedMapActions();
	}
}

function GetIOSWechatSupportedMapActions() {
	return [
		{
			name: "百度地图",
			platform: "web",
			type: "baidu",
		},
		{
			name: "自带地图",
			platform: "ios",
			type: "apple",
		},
	];
}

function GetIOSSupportedMapActions() {
	return [
		{
			name: "百度地图",
			platform: "ios",
			type: "baidu",
		},
		// {
		// 	name: "腾讯地图",
		// 	platform: "ios",
		// 	type: "tencent",
		// },
		{
			name: "高德地图",
			platform: "ios",
			type: "amap",
		},
		{
			name: "自带地图",
			platform: "ios",
			type: "apple",
		},
	];
}
function GetWebSupportedMapActions() {
	return [
		{
			name: "百度地图",
			platform: "web",
			type: "baidu",
		},
	];
}
function GetAndroidSupportedMapActions() {
	return [
		{
			name: "百度地图",
			platform: "android",
			type: "baidu",
		},
		// {
		// 	name: "腾讯地图",
		// 	platform: "android",
		// 	type: "tencent",
		// },
		{
			name: "高德地图",
			platform: "android",
			type: "amap",
		},
	];
}

// platform: ios、android、web
export function OpenMapWithAction(action, lng, lat, addr) {
	let platform = action.platform;
	let type = action.type;
	let targetURL = "";
	let newTab = false;

	if (platform == "ios") {
		if (type == "amap") {
			targetURL =
				"iosamap://navi?sourceApplication=xlwx&poiname=" +
				addr +
				"&lat=" +
				lat +
				"&lon=" +
				lng +
				"&dev=0";
		} else if (type == "baidu") {
			let baiduLoc = TencentMapToBaiduMap(lng, lat);

			targetURL =
				"baidumap://map/navi?location=" +
				baiduLoc.latitude +
				"," +
				baiduLoc.longitude +
				"&query=" +
				addr;
		} else if (type == "tencent") {
			targetURL =
				"qqmap://map/marker?marker=coord:" +
				lat +
				"," +
				lng +
				";title:" +
				addr +
				"&referer=RBMBZ-VOACI-W7ZGO-5KPTA-6TDBV-SXFIY";
		} else if (type == "apple") {
			targetURL =
				"http://maps.apple.com/?sll=" + lat + "," + lng + "&address=" + addr;
		}
	} else if (platform == "android") {
		if (type == "baidu") {
			let baiduLoc = TencentMapToBaiduMap(lng, lat);
			targetURL =
				"bdapp://map/navi?location=" +
				baiduLoc.latitude +
				"," +
				baiduLoc.longitude +
				"&query=" +
				addr;
		} else if (type == "amap") {
			targetURL =
				"androidamap://navi?sourceApplication=xlwx&poiname=" +
				addr +
				"&lat=" +
				lat +
				"&lon=" +
				lng +
				"&dev=0&style=2";
		} else if (type == "tencent") {
			targetURL =
				"qqmap://map/marker?marker=coord:" +
				lat +
				"," +
				lng +
				";title:" +
				addr +
				"&referer=RBMBZ-VOACI-W7ZGO-5KPTA-6TDBV-SXFIY";
		}
	} else {
		// web
		if (type == "baidu") {
			let baiduLoc = TencentMapToBaiduMap(lng, lat);

			targetURL =
				"http://api.map.baidu.com/marker?location=" +
				baiduLoc.latitude +
				"," +
				baiduLoc.longitude +
				"&title=位置&content=" +
				addr +
				"&output=html";
			newTab = true;
		}
	}

	if (targetURL?.length > 0) {
		if (newTab) {
			window.open(targetURL, "_blank");
		} else {
			window.location.href = targetURL;
		}
	}
}

function GetPlatform() {
	let result = {};
	try {
		var u = navigator.userAgent;
		var isChrome = u.match(/Chrome\/([\d.]+)/) || u.match(/CriOS\/([\d.]+)/);
		var isAndroid = u.match(/(Android);?[\s/]+([\d.]+)?/);
		var iosVersion = (function() {
			if (/iP(hone|od|ad)/.test(navigator.platform)) {
				var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
				return [
					parseInt(v[1], 10),
					parseInt(v[2], 10),
					parseInt(v[3] || 0, 10),
				];
			}
		})();
		var chromeVersion = (function() {
			var chrome = navigator.userAgent.match(/Chrome\/(\d+)\./);
			if (chrome) {
				return parseInt(chrome[1], 10);
			}
		})();
		var ios9 = iosVersion && iosVersion[0] >= 9;
		var chrome18 = isChrome && isAndroid && chromeVersion && chromeVersion > 18;
		result = {
			// 移动终端浏览器版本信息
			trident: u.indexOf("Trident") > -1, // IE内核
			presto: u.indexOf("Presto") > -1, // opera内核
			webKit: u.indexOf("AppleWebKit") > -1, // 苹果、谷歌内核
			gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, // 火狐内核
			mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
			iOS: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
			android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, // android终端或uc浏览器
			iPhone: u.indexOf("iPhone") > -1, // 是否为iPhone或者QQHD浏览器
			iPad: u.indexOf("iPad") > -1, // 是否iPad
			webApp: u.indexOf("Safari") == -1, // 是否web应该程序，没有头部与底部
			weChat: u.indexOf("MicroMessenger") > -1,
			UC: u.indexOf("UCBrowser") > -1,
			u3: u.indexOf("U3") > -1,
			chrome: u.indexOf("Chrome") > -1,
			windowsPhone: u.indexOf("Windows Phone") > -1,
			samsung: u.indexOf("Samsung") > -1,
			QQ:
				u.match(/\sQQ/i) != null
					? u.match(/\sQQ/i).toLowerCase() == " qq"
					: false,
			isChrome: isChrome,
			isAndroid: isAndroid,
			iosVersion: iosVersion,
			chromeVersion: chromeVersion,
			ios9: ios9,
			chrome18: chrome18,
		};
	} catch (error) {
		//
	}
	return result;
}
